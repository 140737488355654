<template>
    <div class="gallery-media-container">
        <alert-component :type="alertType" :text="textAlert" v-if="textAlert"></alert-component>
        <spinner-component v-if="showSpinner"></spinner-component>

        <perfect-scrollbar class="media-container">
            <div v-for="(media, key) in medias.filter((i) => !i.is_expired)" :key="key">
                <div class="media-content-container" @click="toggleSendFile(key)">
                    <img v-if="checkImageURL(media.url)" :src="media.url" alt="" :title="media.name ? media.name : media.url" />
                    <video
                        v-else-if="checkVideoURL(media.url)"
                        :src="media.url"
                        alt=""
                        controls
                        :title="media.name ? media.name : media.url"
                    />
                    <i v-else-if="checkDocURL(media.url)" :src="media.url" alt=""
                        ><img src="../assets/pdf-icon.svg" alt="pdf-icon" class="pdf-icon" :title="media.name ? media.name : media.url"
                    /></i>
                    <div v-if="clickItem == key" class="download-file" @click="emitEventSendChat(media.url)">Send File</div>
                </div>
            </div>
            <div class="media-content-container" v-if="!disableGallery">
                <div class="add-new-content">
                    <label for="image">
                        <input
                            type="file"
                            accept="image/jpeg/png/mp4"
                            @change="uploadImage"
                            name="image"
                            id="image"
                            style="display: none"
                        />
                        <b-icon icon="plus-square" class="plus-icon"></b-icon>
                    </label>
                </div>
            </div>
        </perfect-scrollbar>
    </div>
</template>

<script>
import AlertComponent from '@/components/AlertComponent';
import SpinnerComponent from '@/components/SpinnerComponent';

export default {
    components: { AlertComponent, SpinnerComponent },
    name: 'GalleryComponent',
    props: ['hideChat'],
    data() {
        return {
            clickItem: null,
            frontEndUploadedImg: [],
            medias: [],
            showSpinner: false,
            textAlert: false,
            alertType: 'success',
            disableGallery: false,
        };
    },
    methods: {
        toggleSendFile: function (n) {
            console.log(n);
            this.clickItem = n;
        },
        uploadImage(e) {
            let selectedFiles = e.target.files;
            if (selectedFiles.length > 0) {
                this.showSpinner = true;
            }
            for (let i = 0; i < selectedFiles.length; i++) {
                let file = selectedFiles[i];
                console.log(file);

                this.apiFileUpload(file);

                this.frontEndUploadedImg.push(selectedFiles[i]);
            }

            /* TODO: to check if it needed 
            for (let i = 0; i < this.frontEndUploadedImg.length; i++) {
                let reader = new FileReader();
                reader.onload = () => {
                    this.$refs.image[i].src = reader.result;
                };

                reader.readAsDataURL(this.frontEndUploadedImg[i]);
            } 
            */
        },
        apiFileUpload: function (file) {
            let meetingData = this.$store.getters.getMeetingData;
            let userToken = this.$store.getters.getUserToken;
            console.log(userToken);

            let bodyFormData = new FormData();
            bodyFormData.append('file', file);
            bodyFormData.append('folder', 'meeting_' + btoa(meetingData.thread_id));

            this.$http
                .post(`${this.$store.getters.getMediaServerUrl}/api/v1/admin/files`, bodyFormData, {
                    headers: { 'Content-Type': 'multipart/form-data', Authorization: `Bearer ${userToken}` },
                })
                .then(() => {
                    //this.textAlert = 'File uploaded.';
                    //this.removeAlert();
                    this.apiGetFileListByFolder();
                    this.emitEventUploaded(file);
                    //this.emitEventSendChat(response.data.url);

                    this.showSpinner = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.emitEventUploaded(null);
                    this.showSpinner = false;
                });
        },
        apiGetFileListByFolder: function () {
            let meetingData = this.$store.getters.getMeetingData;
            let userToken = this.$store.getters.getUserToken;
            let threadId = btoa(meetingData.thread_id);

            this.$http
                .get(`${this.$store.getters.getMediaServerUrl}/api/v1/admin/files/meeting_${threadId}`, {
                    headers: { Authorization: `Bearer ${userToken}` },
                })
                .then((response) => {
                    this.putMedias(response.data);
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        putMedias: function (medias) {
            console.log(medias);
            for (let m of medias) {
                this.putMedia(m);
            }
        },
        putMedia: function (media) {
            if (!this.medias.find((m) => m.url === media.url)) {
                this.medias.push(media);
            }
        },
        checkImageURL: function (url) {
            return !url ? false : url.toLowerCase().match(/\.(jpeg|jpg|gif|png)$/) != null;
        },
        checkVideoURL: function (url) {
            return !url ? false : url.toLowerCase().match(/\.(mp4|mov)$/) != null;
        },
        checkDocURL: function (url) {
            return !url ? false : url.toLowerCase().match(/\.(pdf)$/) != null;
        },
        removeAlert: function () {
            let vm = this;
            setTimeout(function () {
                vm.textAlert = false;
            }, 1000);
        },
        emitEventUploaded: function (file) {
            if (file) {
                this.$emit('fileUploaded', file);
            } else {
                this.$emit('fileUploaded', null);
            }
        },
        emitEventSendChat: function (url) {
            //this.textAlert = 'File uploaded.';
            //this.removeAlert();

            this.$emit('fileSent', url);
        },
    },
    mounted() {
        if (this.$store.getters.getMeetingData.id == '' && this.$route.params.meeting_id != undefined) {
            var id = this.$route.params.meeting_id;
            var passcode = this.$route.params.meeting_passcode;

            this.$store.dispatch('changeMeeting', { id, passcode });
        }

        this.apiGetFileListByFolder();

        let userData = this.$store.getters.getUserData;
        console.log(userData);
        if (!userData.email && !userData.password) {
            this.disableGallery = true;
        }
    },
};
</script>

<style scoped>
.pdf-icon {
    padding: 40px;
}

.ps {
    position: unset;
    height: 100%;
    max-width: unset;
    z-index: 1000;
}

.ps--active-x > .ps__rail-x,
.ps--active-y > .ps__rail-y {
    z-index: 10 !important;
}

/*ps__rail-x {*/
/*  width: 1740px;*/
/*  z-index: 10 !important;*/
/*}*/

.smallChat {
    width: calc(100vw - (100vw / 12) * 3 - 305px) !important;
}

.gallery-media-container,
.media-container {
    display: flex;
    z-index: 9;
    width: 100%;
}

.media-container {
    /*width: calc(100vw - 180px);*/
    overflow: scroll;
}

.media-content-container {
    position: relative;
    display: flex;
    align-items: center;
    margin-right: 45px;
    height: 125px;
}

.media-content-container img,
.media-content-container video {
    width: 125px;
    max-height: 125px;
}

.download-file {
    position: absolute;
    top: 0;
    width: 100%;
    background: white;
    padding: 5px;
    cursor: pointer;
}

.add-new-content {
    margin-left: 45px;
}

.plus-icon {
    width: 50px;
    height: 50px;
    color: #ffffff;
    cursor: pointer;
}
</style>
