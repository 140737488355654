<template>
    <div class="contactList">
        <spinner-component v-if="showSpinner"></spinner-component>

        <b-row no-gutters>
            <b-col cols="10">
                <div class="form-input mr-2">
                    <b-form-input v-model="contact_by_email" placeholder="Invite contact by email..." class="mb-2"></b-form-input>
                </div>
            </b-col>
            <b-col>
                <div class="cta">
                    <button @click="inviteContactByEmail(contact_by_email)">SEND</button>
                </div>
            </b-col>
        </b-row>

        <div class="form-input">
            <b-form-input v-model="search_username" placeholder="Search..." class="mb-2"></b-form-input>
        </div>

        <div v-if="!showSpinner" class="contacts">
            <perfect-scrollbar>
                <div
                    v-for="(user, index) in filteredUsers"
                    :key="index"
                    :class="index % 2 == 0 ? 'contact_grey' : 'contact_white'"
                    class="cursor-pointer"
                    @click="inviteContactSelected(user.username)"
                    role="button"
                >
                    <b-row no-gutters class="row_div_user_padding">
                        <b-col class="cols-custom-10" cols="0">
                            <div class="chat-pic">
                                <p style="padding: 4px">{{ user.username.substring(0, 1).toUpperCase() }}</p>
                            </div>
                        </b-col>
                        <b-col class="text-left name" cols="6">
                            {{ user.username }}
                        </b-col>
                        <b-col class="text-right">
                            <inline-svg class="" :src="require('../assets/contact_call.svg')" width="35" />
                        </b-col>
                    </b-row>
                </div>
            </perfect-scrollbar>
        </div>
    </div>
</template>

<script>
import SpinnerComponent from '@/components/SpinnerComponent';

export default {
    components: { SpinnerComponent },
    name: 'ContactListComponent',
    data() {
        return {
            search_username: '',
            contact_by_email: '',
            users: [],
            showSpinner: true,
        };
    },
    mounted() {
        this.getList();
    },
    methods: {
        getList() {
            let userToken = this.$store.getters.getUserToken;

            this.$http
                .get(`${this.$store.getters.getMediaServerUrl}/api/v1/admin/users`, {
                    headers: { Authorization: `Bearer ${userToken}` },
                })
                .then((response) => {
                    this.users = response.data;
                    this.showSpinner = false;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        inviteContactSelected(email) {
            // const joinURL = window.location.origin + '/start/' + `${this.$store.getters.getMeetingData.id}/${this.$store.getters.getMeetingData.passcode}`;
            // const joinURL = `${window.location.origin}/start?link=${this.$store.getters.getMeetingData.join_url}`;

            if (this.$store.getters.inMeeting) {
                this.$emit('inviteContacts', {
                    meeting: {
                        id: this.$store.getters.getMeetingData.id,
                        passcode: this.$store.getters.getMeetingData.passcode,
                        join_url: this.$store.getters.getMeetingData.join_url,
                        thread_id: this.$store.getters.getMeetingData.thread_id,
                    },
                    attendees: [email],
                });
                /* this.$emit('inviteContacts', {
          meeting: {'id': this.$store.getters.getMeetingData.id, 'attendeePasscode' : this.$store.getters.getMeetingData.passcode, 'joinURL': joinURL},
          attendees: [email]
        }); */
            } else {
                this.$emit('startMeeting', [email]);
            }
        },
        inviteContactByEmail(email) {
            if (this.validateEmail(email)) {
                this.inviteContactSelected(email);
                this.contact_by_email = '';
            } else {
                this.$emit('showErrorEmailAlert', email);
            }
        },
        validateEmail(email) {
            return String(email)
                .toLowerCase()
                .match(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                );
        },
    },
    computed: {
        filteredUsers: function () {
            var self = this;
            return this.users.filter(function (user) {
                return user.username.toLowerCase().indexOf(self.search_username.toLowerCase()) >= 0;
            });
        },
    },
};
</script>

<style scoped>
.contactList {
    padding: 30px 15px 50px 15px;
    background: #454545;
    height: 97%;
    border-left: 3px solid #e4e4e4;
    /*position: absolute !important;*/
    /*padding: 30px 15px 50px 15px;*/
    /*background: #B2B1AEB3;*/
    /*transition: 0.1s background;*/
    /*top: 75px;*/
    /*bottom: 0;*/
    /*width: 100%;*/
    box-sizing: border-box;
    overflow-y: hidden;
}

.contacts {
    position: relative;
    height: calc(100% - 75px);
    top: 0px;
    bottom: 0px;
    overflow: auto;
}

.form-input {
    background: #c6c6c6;
}

.contact_white {
    background: #ffffff;
}

.contact_grey {
    background: #e4e4e4;
}

.contact_white:hover,
.contact_grey:hover {
    background: #ffb080;
}

.contact_logo {
    position: absolute;
    left: 0;
    top: -5px;
}

.row_div_user_padding {
    padding: 10px 16px 10px 20px;
}

.ps {
    width: 100%;
    max-width: 1120px;
    overflow: auto;
    overflow-x: hidden;
}

.ps__rail-y:hover > .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y,
.ps__rail-y.ps--clicking .ps__thumb-y {
    background-color: grey !important;
}

.spinner {
    top: 40%;
    width: 30% !important;
}

.name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    top: 5px;
}

.cols-custom-10 {
    flex: 0 0 10.666667%;
    max-width: 16.666667%;
}

.cta button {
    background: rgb(0, 162, 215);
    border-radius: 12px;
    border: 0;
    font-size: 14px;
    color: #ffffff;
    font-weight: 600;
    padding: 8px 8px;
    width: 100%;
}
</style>
