<template>
  <div id="annotation_container"></div>
</template>

<script>
import P5 from 'p5'

export default {
  name: "AnnotationComponent",
  props: [],
  data() {
    return {
      p5: null,
      clr: null,
      canvas_width: 0,
      canvas_height: 0,
      active_tool: 'line',
    };
  },
  sockets: {
    connect: function () {
      console.log('annotation component: socket connected');
    },
    disconnect: function () {
      console.log('annotation component: socket disconnected');
    },
    xpv_annotation_draw: function (data) {
      console.log('annotation component: received: xpv_annotation_draw', data);
    }
  },
  methods: {
    socketEmit(data) {
      if (this.$store.getters.inMeeting) {
        data.canvasW = this.canvas_width;
        data.canvasH = this.canvas_height;
        data.room = 'meeting.teams.' + btoa(this.$store.getters.getMeetingData.thread_id);

        this.$socket.emit('xpv_annotation_draw', data);

        //console.log('sending:', data.action);
      }
    },
    clearCanvas() {
      this.p5.clear();
      this.socketEmit({action: 'canvas:clear'});
    },
    resizeCanvas() {
      this.p5.windowResized();
    },
    setActiveTool(tool) {
      this.clearCanvas();
      this.active_tool = tool;
      this.p5.cursor(this.p5.default);
    },
    getActiveTool() {
      return this.active_tool;
    },
    sketch(s) {
      /*let w = window.innerWidth;
      let h = window.innerHeight;

      s.setup = () => {
        s.createCanvas(w, h);
      };

      s.draw = () => {
        s.background(33, 33, 33);
        s.ellipse(50, 50, 100, 100);

        // call your method:
        //s.yourMethod(x, y);
      };*/

      // create methods:
      /*s.yourMethod = (x, y) => {
        // your method
      }*/

      s.setup = () => {
        //let h = 400
        //let w = 400
        //socket = io.connect('http://192.168.1.145:3000')
        //socket.on('mouse', newDrawing);

        this.s = s;

        let canvasDiv = document.getElementById('annotationTool');
        canvasDiv.removeAttribute('hidden')
        let ratio = 1280 / 720;
        this.canvas_height = ((canvasDiv.offsetHeight * ratio) / ratio) - 25; // 25 is gallery height
        this.canvas_width = this.canvas_height * ratio;
        let canvas = s.createCanvas(this.canvas_width, this.canvas_height);
        canvas.parent('annotation_container');

        //s.background(51);
        //this.clr = s.random(360);
        //s.noStroke();

        s.stroke(250, 0, 0);
        s.strokeWeight(5);
      }


      s.windowResized = () => {
        console.log('canvas resized');

        // w: 1326; h: 680
        let canvasDiv = document.getElementById('annotationTool');
        let ratio = 1280 / 720;
        //this.canvas_height = ((canvasDiv.offsetHeight * ratio) / ratio) - 25; // 25 is gallery height
        this.canvas_height = ((canvasDiv.offsetHeight * ratio) / ratio) - 25;
        this.canvas_width = this.canvas_height * ratio;
        s.resizeCanvas(this.canvas_width, this.canvas_height);

        this.clearCanvas();
      }

      /*s.displayDot = (x, y, color, color2 = 100) => {
        s.colorMode(s.HSB)
        s.fill(color, 100, color2)
        s.ellipse(x, y, 10)
        s.colorMode(s.RGB)
      }*/

      s.draw = () => {
        let data = {
            action: 'draw:line',
            mouseX: s.mouseX,
            mouseY: s.mouseY,
            pmouseX: s.pmouseX,
            pmouseY: s.pmouseY,
            //color: this.clr
        }

        if (this.active_tool === 'line' && s.mouseIsPressed === true) {
          s.cursor(s.default);
          s.line(s.mouseX, s.mouseY, s.pmouseX, s.pmouseY);
          data.action = 'draw:line';
          this.socketEmit(data);
        } 
      }

      s.mouseMoved = () => {
        if (this.active_tool === 'pointer') {
          let data = {
            action: 'draw:line',
            mouseX: s.mouseX,
            mouseY: s.mouseY,
            pmouseX: s.pmouseX,
            pmouseY: s.pmouseY,
            //color: this.clr
          }
          s.cursor(s.CROSS);
          data.action = 'draw:pointer';
          this.socketEmit(data);
        }
      }

      s.mousePressed = () => {
        //s.mouseDragged()
      }

      s.mouseDragged = () => {
        /*this.clr += 1
        this.clr = s.upgradeColor(this.clr)
        let data = {
          x: s.mouseX,
          y: s.mouseY,
          color: this.clr
        }
        //socket.emit('mouse', data);
        console.log('sending:', s.mouseX + ',', s.mouseY + ',', this.clr)
        s.noStroke()
        s.displayDot(s.mouseX, s.mouseY, this.clr)*/
      }

      /*s.newDrawing = (data) => {
        data.color = s.upgradeColor(data.color)
        s.displayDot(data.x, data.y, data.color, 100)
      }*/

      /*s.upgradeColor = (c) => {
        if (c < 0) {
          c = 360 - c
        } else if (c > 360) {
          c = c % 360
        }
        return c
      }*/

    }

  },
  mounted() {
    this.p5 = new P5(this.sketch, 'share_canvas');
  },
  destroyed() {
    this.clearCanvas(); // send clear event to socket
    this.p5.remove();
    this.p5 = null;
  }
};
</script>

<style scoped>
#annotation_container {
  position: absolute;
  z-index: 3000;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
</style>
