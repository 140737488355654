<template>
    <div class="alert-box" :class="{ success : type == 'success', 'success-2' : type == 'success-2', error : type == 'error', warning : type == 'warning' }">
        <span v-html="text"></span>
    </div>
</template>

<script>
    export default {
        name: "AlertComponent",
        props: ["type","text"],
        data() {
            return {
                selectedItem: null
            };
        },
        methods: {
            onItemSelected(item) {
                this.selectedItem = item;
            }
        }
    };
</script>

<style scoped>
.alert-box {
    box-shadow: 5px 5px 10px black;
    position: absolute;
    top: 10%;
    left: 50%;
    color: white;
    transform: translate(-50%, -50%);
    font-weight: bold;
    letter-spacing: 0.1rem;
    text-align: center;
    padding: 8px 16px;
    border: solid 1px;
    border-radius: 20px;
    z-index: 101;
}
    /* .alert-box {
        position: absolute;
        top: 0;
        z-index: 9999;
        left: 50%;
        transform: translateX(-50%);
        max-width: 700px;
        width: 22%;
        border: 2px solid #FFFFFF;
        border-radius: 0 0 24px 24px;
        color: #FFFFFF;
        text-align: center;
        font-family: 'Roboto',sans-serif;
        font-size: 20px;
        padding: 20px 10px 10px;
    } */

    .success-2 {
        background: #00b05b;
        border-color: #00b05b;
    }
    .success {
        background: #0084b0;
        border-color: #00a2d7;
    }

    .warning {
        background: #FFBF08;
        border-color: #FFBF08;
    }

    .error {
        background: #800001;
        border-color: #800001;
    }
</style>
