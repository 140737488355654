import Vue from 'vue';
import Vuex from 'vuex';
import App from './App.vue';
import router from './router';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import { InlineSvgPlugin } from 'vue-inline-svg';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import LoadScript from 'vue-plugin-load-script';
import axios from 'axios';
import store from './store';
import PerfectScrollbar from 'vue2-perfect-scrollbar';
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css';

import '@/components/AlertComponent';
import '@/components/SpinnerComponent';
import '@/components/GalleryComponent';
import '@/components/AnnotationComponent';

window.$ = require('jquery');
window.jQuery = require('jquery');
Vue.prototype.$http = axios;

Vue.use(Vuex);
Vue.use(LoadScript);
// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);
Vue.use(InlineSvgPlugin);
Vue.use(PerfectScrollbar);

Vue.mixin({
    methods: {
        /**
         * Extract thread id from meeting link
         * 
         * @param {String} link 
         * @returns 
         */
        extractThreadIdFromMeetingLink: (link) => {
            // Extract the thread id from link
            const regex = /meetup-join\/([^\/]+)\//; // eslint-disable-line
            var match = regex.exec(link);

            if (match) {
                return match[1];
            }
            return null;
        },

        /**
         * Check if meeting link format is supported
         *
         * @param {String} link
         * @returns
         */
        validateTeamsMeetingLink(_link) {
            // Decode url
            let link = decodeURIComponent(_link);

            // First regex
            var regex = /(?:^|\W)https:\/\/teams.microsoft.com\/l\/meetup-join\/(\w+)(?!\w)/g;
            var match = regex.exec(link);
            if (match) {
                return match[1];
            }

            // Second regex
            regex = /(?:^|\W)https:\/\/teams.microsoft.com\/dl\/launcher\/launcher.html\?url=\/_#\/l\/meetup-join\/(\w+)(?!\w)/g;
            match = regex.exec(link);
            if (match) {
                let linkSanitized = link.replace('dl/launcher/launcher.html?url=/_#/', '');
                return linkSanitized;
            }

            // Return with error alert
            this.textAlert = 'Please enter a valid Teams meeting link';
            return null; 
        },
    },
});

Vue.config.productionTip = false;

fetch('/config/' + window.location.hostname + '.json')
    .then((resp) => {
        resp.json().then((config) => {
            console.log('config', config);
            Vue.prototype.$config = config;
            new Vue({
                router,
                store,
                render: (h) => h(App),
            }).$mount('#app');
        });
    })
    .catch((e) => console.log(e));
