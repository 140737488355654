<template>
    <div>
        <div class="meeting">
            <b-container fluid style="padding-left: 0; padding-right: 0; overflow: hidden; filter: blur(2px)">
                <b-row class="top-bar" align-v="center">
                    <!-- top bar - header -->
                    <b-col cols="2" class="company-name">
                        <img src="../assets/fre_logo.png" />
                    </b-col>
                    <b-col cols="3" class="meeting-url unselectable">
                        <p>URL:&nbsp;</p>
                        <div style="display: inline">
                            <span>http://localhost:8080/start/12345</span>
                            <inline-svg :src="require('../assets/link_to_url.svg')" width="15" height="15" class="copy" />
                        </div>
                    </b-col>
                    <b-col cols="4" class="storage-used small-txt unselectable">
                        Storage Used: 20% of 50GB
                        <b-progress value="20" height="5px" variant="info" style="background: #c8c8c7; max-width: 210px"></b-progress>
                    </b-col>
                    <b-col cols="2" class="unselectable">
                        <b-row align-v="center">
                            <b-col cols="10" class="user" style="display: flex">
                                <img class="profile-pic" src="https://picsum.photos/25/25" alt="" />
                                <span style="font-size: 14px">Mario Rossi</span>
                                <inline-svg :src="require('../assets/caller_log_out.svg')" width="15" height="15" class="ml-2" />
                            </b-col>
                        </b-row>
                    </b-col>
                    <b-col cols="0" no-gutters style="position: absolute; right: 0; padding-right: 20px; padding-left: 20px">
                        <div class="chat-icon-container" style="margin-bottom: 0; margin-right: 0px">
                            <div class="svg-icon">
                                <inline-svg :src="require('../assets/hang_up.svg')" width="48" height="48" />
                            </div>
                        </div>
                    </b-col>
                </b-row>
                <b-row no-gutters>
                    <b-col class="container-vid unselectable" style="background: #d3d3d3" cols="12">
                        <div class="remotevid" style="height: calc(100vh - 75px - 75px)"></div>

                        <div class="media bottomMedia">
                            <div class="toggle-gallery">
                                <b-icon icon="chevron-up"></b-icon>
                                <span>GALLERY</span>
                            </div>
                        </div>
                    </b-col>
                    <b-col class="chat-sidebar pdlr20 unselectable" cols="0">
                        <!-- sidebar chat -->
                        <div class="arrow-right hide"></div>

                        <div>
                            <div class="chat-icon-container">
                                <div class="svg-icon">
                                    <inline-svg :src="require('../assets/add_people_v2_grey.svg')" width="48" height="48" />
                                </div>
                            </div>
                            <div class="chat-icon-container">
                                <div class="svg-icon">
                                    <inline-svg :src="require('../assets/group_number_grey.svg')" width="48" height="48" />
                                </div>
                            </div>
                            <div class="chat-icon-container">
                                <div class="svg-icon">
                                    <inline-svg :src="require('../assets/chat_grey.svg')" width="48" height="48" />
                                </div>
                            </div>
                            <div class="chat-icon-container display_none">
                                <div class="svg-icon">
                                    <inline-svg :src="require('../assets/call.svg')" width="48" height="48" />
                                </div>
                            </div>
                        </div>

                        <div class="chat-profile-container sr-only">
                            <img class="chat-sidebar-pic" src="https://picsum.photos/48/48" alt="" />
                            <span class="hide">Mario Rossi</span>
                        </div>
                    </b-col>
                </b-row>

                <b-row class="bottom-bar" no-gutters>
                    <div class="bottom-bar-container">
                        <div class="btn-container"></div>
                        <div class="btn-container unselectable">
                            <div class="bottom-btn orange-border no-border-change zoom">
                                <div class="zoom-levels">
                                    <div class="zoom-level">
                                        <p>1x</p>
                                        <div class="circle-zoom zoomActive"></div>
                                    </div>
                                    <div class="zoom-level">
                                        <p>2x</p>
                                        <div class="circle-zoom"></div>
                                    </div>
                                    <div class="zoom-level">
                                        <p>3x</p>
                                        <div class="circle-zoom"></div>
                                    </div>
                                    <div class="zoom-level">
                                        <p>4x</p>
                                        <div class="circle-zoom"></div>
                                    </div>
                                    <div class="zoom-level">
                                        <p>5x</p>
                                        <div class="circle-zoom"></div>
                                    </div>
                                </div>
                                <span>Zoom</span>
                            </div>
                            <button class="bottom-btn orange-border toggle-light">
                                <inline-svg :src="require('../assets/lights_default.svg')" width="34" />
                                <div class="mb-bottom-btn"><span>Lights ON</span></div>
                            </button>
                            <button class="bottom-btn orange-border take-photo">
                                <inline-svg :src="require('../assets/camera_default.svg')" width="34" />
                                <div class="mb-bottom-btn"><span>TAKE Photo</span></div>
                            </button>
                            <button class="bottom-btn orange-border toggle-video record-video-btn">
                                <inline-svg :src="require('../assets/video_default.svg')" width="37" />
                                <div class="mb-bottom-btn"><span>RECORD Video</span></div>
                            </button>
                            <button class="bottom-btn blue-border toggle-video">
                                <inline-svg :src="require('../assets/annotation_default.svg')" width="38" />
                                <div class="mb-bottom-btn"><span>Annotations</span></div>
                            </button>
                        </div>
                        <div class="btn-container" style="padding: 16px 20px">
                            <button class="full-screen">
                                <inline-svg :src="require('../assets/to_enlarge.svg')" width="48" />
                            </button>
                        </div>
                    </div>
                </b-row>
            </b-container>

            <b-container fluid class="background sr-only">
                <alert-component type="error" :text="textAlert" v-if="textAlert"></alert-component>
                <spinner-component v-if="showSpinner"></spinner-component>

                <div class="login-page-container">
                    <div class="fre-logo-container">
                        <img class="fre-logo" src="../assets/fre_logo.svg" alt="fusion remote logo" />
                    </div>

                    <!-- login container -->
                    <div class="login-container">
                        <b-container>
                            <b-row>
                                <b-col>
                                    <h2 class="header-paragraph" v-if="guestMode">Join as guest</h2>
                                    <h2 class="header-paragraph" v-if="!guestMode && !userLogged">Sign In</h2>
                                    <h2 class="header-paragraph" v-if="!joinUrl && userLogged">Join as</h2>
                                </b-col>
                            </b-row>

                            <b-row v-if="guestMode">
                                <b-col>
                                    <div class="meeting-container" v-if="!joinUrl">
                                        <div class="input-container">
                                            <input
                                                type="text"
                                                placeholder="Your meeting ID"
                                                v-model="meetingId"
                                                autocomplete="new-password"
                                            />
                                        </div>
                                        <div class="input-container">
                                            <input
                                                type="password"
                                                placeholder="Your meeting passcode"
                                                v-model="meetingPasscode"
                                                autocomplete="new-password"
                                            />
                                        </div>
                                    </div>
                                    <div class="input-container">
                                        <input type="text" placeholder="Your name" v-model="displayName" autocomplete="new-password" />
                                    </div>
                                    <div class="cta btnWidth">
                                        <button
                                            @click="
                                                storeGuestData(displayName) && storeMeetingData(meetingId, meetingPasscode) && joinMeeting()
                                            "
                                        >
                                            Join the meeting
                                        </button>
                                    </div>

                                    <div class="sign-in" @click="guestMode = false">
                                        <a href="#">or sign in</a>
                                    </div>
                                </b-col>
                            </b-row>

                            <b-row v-else-if="!guestMode">
                                <b-col>
                                    <div class="account-login-container" v-if="!userLogged">
                                        <form>
                                            <div class="input-container">
                                                <input
                                                    id="email-username"
                                                    type="text"
                                                    placeholder="Your email"
                                                    v-model="email"
                                                    v-on:keyup.enter="userSignIn()"
                                                />
                                            </div>
                                            <div class="input-container pwd-input-container">
                                                <input
                                                    id="password"
                                                    placeholder="Your password"
                                                    :type="passwordFieldType"
                                                    v-model="password"
                                                    v-on:keyup.enter="userSignIn()"
                                                />
                                                <img class="show-pwd" src="../assets/eye.svg" alt="show pwd" @click="switchVisibility" />
                                            </div>
                                            <div class="cta btnWidth">
                                                <button type="button" @click="userSignIn()">Sign in</button>
                                            </div>
                                            <div class="sign-in">
                                                <img src="../assets/microsoft.png" height="20" />
                                                <a :href="msoauthUrl">Login with Microsoft</a>
                                            </div>
                                            <div class="sign-in" @click="guestMode = true" v-if="!guestMode">
                                                <a href="#">or join as guest</a>
                                            </div>
                                        </form>
                                    </div>

                                    <div class="meeting-container" v-if="userLogged && !joinUrl">
                                        <b-row class="user-info">
                                            <b-col cols="12" align="center">
                                                <div class="mb-2"><img class="profile-pic" src="https://picsum.photos/25/25" alt="" /></div>
                                                <div>{{ this.$store.getters.getUserData.display_name }}</div>
                                            </b-col>
                                        </b-row>
                                        <div class="sign-in logout">
                                            <a href="#" @click="logout()">Logout</a>
                                        </div>
                                        <div class="input-container">
                                            <input
                                                type="text"
                                                placeholder="Your meeting ID"
                                                v-model="meetingId"
                                                autocomplete="new-password"
                                            />
                                        </div>
                                        <div class="input-container">
                                            <input
                                                type="password"
                                                placeholder="Your meeting passcode"
                                                v-model="meetingPasscode"
                                                autocomplete="new-password"
                                            />
                                        </div>
                                        <div class="cta">
                                            <button @click="storeMeetingData(meetingId, meetingPasscode) && joinMeeting()">
                                                Join the meeting
                                            </button>
                                        </div>

                                        <div class="sign-in" v-if="userLogged">
                                            <a href="./meeting">Go to Meeting View</a>
                                        </div>
                                    </div>
                                </b-col>
                            </b-row>

                            <b-row>
                                <b-col class="d-flex justify-content-center j-logo-bottom">
                                    <img src="../assets/j-logo-full.svg" alt="jre logo" />
                                </b-col>
                            </b-row>
                        </b-container>
                    </div>
                    <!-- login container -->
                </div>
            </b-container>
        </div>
        <alert-component type="error" :text="textAlert" v-if="textAlert"></alert-component>
        <spinner-component v-if="showSpinner"></spinner-component>

        <div class="login-page-container">
            <div class="fre-logo-container">
                <img class="fre-logo" src="../assets/fre_logo.svg" alt="fusion remote logo" />
            </div>

            <!-- login container -->
            <div class="login-container">
                <b-container>
                    <b-row>
                        <b-col>
                            <h2 class="header-paragraph" v-if="guestMode">Join as guest</h2>
                            <div class="tab" v-if="guestMode && false">
                                <button
                                    class="tab-button link"
                                    @click="changeGuestMode('link')"
                                    :class="{ active: selectedGuestModeTab === 'link' }"
                                >
                                    Link
                                </button>
                                <button
                                    class="tab-button id-passcode"
                                    @click="changeGuestMode('id-passcode')"
                                    :class="{ active: selectedGuestModeTab === 'id-passcode' }"
                                >
                                    ID and Passcode
                                </button>
                            </div>
                            <h2 class="header-paragraph" v-if="!guestMode && !userLogged">Sign In</h2>
                            <h2 class="header-paragraph" v-if="!joinUrl && userLogged">Join as</h2>
                        </b-col>
                    </b-row>

                    <b-row v-if="guestMode">
                        <b-col>
                            <!-- ID and Passcode tab -->
                            <div class="meeting-container" v-if="!joinUrl && selectedGuestModeTab === 'id-passcode'">
                                <div class="input-container">
                                    <input type="text" placeholder="Your meeting ID" v-model="meetingId" autocomplete="new-password" />
                                </div>
                                <div class="input-container">
                                    <input
                                        type="password"
                                        placeholder="Your meeting passcode"
                                        v-model="meetingPasscode"
                                        autocomplete="new-password"
                                    />
                                </div>
                            </div>
                            <!-- Link tab -->
                            <div class="meeting-container" v-if="!joinUrl && selectedGuestModeTab === 'link'">
                                <div class="input-container">
                                    <input type="text" placeholder="Meeting link" v-model="meetingLink" autocomplete="new-password" />
                                </div>
                            </div>
                            <div class="input-container">
                                <input type="text" placeholder="Your name" v-model="displayName" autocomplete="new-password" />
                            </div>
                            <div class="cta btnWidth">
                                <button
                                    v-if="selectedGuestModeTab === 'link'"
                                    @click="storeGuestData(displayName) && validateTeamsMeetingLink(meetingLink) && storeMeetingLinkData(meetingLink) && joinMeeting()"
                                >
                                    Join the meeting
                                </button>
                                <button
                                    v-if="selectedGuestModeTab === 'id-passcode'"
                                    @click="storeMeetingData(meetingId, meetingPasscode) && storeGuestData(displayName) && joinMeeting()"
                                >
                                    Join the meeting
                                </button>
                            </div>

                            <div class="sign-in" @click="guestMode = false">
                                <a href="#">or sign in</a>
                            </div>
                        </b-col>
                    </b-row>

                    <b-row v-else-if="!guestMode">
                        <b-col>
                            <div class="account-login-container" v-if="!userLogged">
                                <form>
                                    <div class="input-container">
                                        <input type="email" placeholder="Your email" v-model="email" v-on:keyup.enter="userSignIn()" />
                                    </div>
                                    <div class="input-container pwd-input-container">
                                        <input
                                            placeholder="Your password"
                                            :type="passwordFieldType"
                                            v-model="password"
                                            v-on:keyup.enter="userSignIn()"
                                        />
                                        <img class="show-pwd" src="../assets/eye.svg" alt="show pwd" @click="switchVisibility" />
                                    </div>
                                    <div class="cta btnWidth">
                                        <button type="button" @click="userSignIn()">Sign in</button>
                                    </div>
                                    <div class="sign-in">
                                        <img src="../assets/microsoft.png" height="20" /> <a :href="msoauthUrl">Login with Microsoft</a>
                                    </div>
                                    <div class="sign-in" @click="guestMode = true" v-if="!guestMode">
                                        <a href="#">or join as guest</a>
                                    </div>
                                </form>
                            </div>

                            <div class="meeting-container" v-if="userLogged && !joinUrl">
                                <b-row class="user-info">
                                    <b-col cols="12" align="center">
                                        <div class="mb-2"><img class="profile-pic" src="https://picsum.photos/25/25" alt="" /></div>
                                        <div>{{ this.$store.getters.getUserData.display_name }}</div>
                                    </b-col>
                                </b-row>
                                <div class="sign-in logout">
                                    <a href="#" @click="logout()">Logout</a>
                                </div>
                                <div class="meeting-container" v-if="!joinUrl && selectedGuestModeTab === 'link'">
                                    <div class="input-container">
                                        <input type="text" placeholder="Meeting link" v-model="meetingLink" autocomplete="new-password" />
                                    </div>
                                </div>
                                <div class="input-container">
                                    <input type="text" placeholder="Your name" v-model="displayName" autocomplete="new-password" />
                                </div>
                                <!-- <div class="input-container">
                  <input type="text" placeholder="Your meeting ID" v-model="meetingId" autocomplete="new-password">
                </div>
                <div class="input-container">
                  <input type="password" placeholder="Your meeting passcode" v-model="meetingPasscode"
                    autocomplete="new-password">
                </div> -->
                                <div class="cta">
                                    <button @click="storeMeetingLinkData(meetingLink) && validateTeamsMeetingLink(meetingLink) && joinMeeting()">Join the meeting</button>
                                    <!-- <button @click="storeMeetingData(meetingId, meetingPasscode) && joinMeeting()">Join
                    the meeting
                  </button> -->
                                </div>

                                <div class="sign-in" v-if="userLogged">
                                    <a href="./meeting">Go to Meeting View</a>
                                </div>
                            </div>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col class="d-flex justify-content-center j-logo-bottom">
                            <img src="../assets/j-logo-full.svg" alt="jre logo" />
                        </b-col>
                    </b-row>
                </b-container>
            </div>
            <!-- login container -->
        </div>
    </div>
</template>

<style scoped>
.background {
    height: 100vh;
    background-color: red;
    /*
  url("../assets/bg_start.png");
  background-size: cover;
  */
}

.login-page-container {
    position: absolute;
    max-width: 600px;
    height: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 13px;
    display: inline-block;
    text-align: start;
    padding: 40px 100px;
    background: #ffffff;
}

.fre-logo-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 37px;
}

.fre-logo {
    margin-bottom: 10px;
}

.line {
    margin-bottom: 7px;
}

.header-paragraph {
    color: #131523;
    font: normal normal 600 20px Roboto;
    letter-spacing: 0.24px;
    text-align: center;
}

.input-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 13px;
}

.user-info {
    margin-top: 10px;
}

input {
    padding-left: 15px;
    background: #f7e40833;
    border: 0;
}

input:focus {
    outline: none;
}

.input-container label {
    margin: 0 0 0 15px;
    font: normal normal bold 12px/20px Roboto;
    letter-spacing: 0.12px;
    color: #131523;
}

.input-container input {
    margin-top: 0;
    margin-bottom: 0;
    height: 32px;
    font: normal normal bold 12px/20px Roboto;
    letter-spacing: 0.12px;
    color: #131523;
}

::placeholder {
    font-weight: 400;
    color: #808080;
    font-size: 14px;
}

.logout {
    margin-top: 10px !important;
    margin-bottom: 15px !important;
}

.sign-in {
    text-align: center;
    margin: 25px 0;
    font-weight: 600;
}

.sign-in a {
    text-transform: uppercase;
    text-decoration: underline;
    color: #131523;
    font-size: 12px;
}

.pwd-input-container {
    position: relative;
}

.show-pwd {
    cursor: pointer;
    position: absolute;
    right: -35px;
    top: 8px;
    width: 24px;
    height: 15px;
}

.cta {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    text-transform: uppercase;
}

.cta button {
    width: 100%;
    background: rgb(0, 162, 215);
    border-radius: 18px;
    border: 0;
    font-size: 14px;
    color: #ffffff;
    font-weight: 600;
    padding: 8px 73px;
    text-transform: uppercase;
}

.or img {
    margin-top: 15px;
    width: 100%;
}

/*  guest  */

.join-guest-container {
    margin-top: 25px;
}

.cta-guest button {
    padding: 8px 43px;
}

.j-logo-bottom {
}

.j-logo-bottom img {
    width: 110px;
}

.btnWidth {
    width: 280px;
}

/* Guset mode tab */

.tab {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
    overflow: hidden;
    background-color: white;
}

.tab .tab-button {
    background-color: inherit;
    border-bottom: 2px solid transparent;
    float: left;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 4px 14px;
    transition: 0.3s;
    font-size: 17px;
}

.tab .tab-button:hover {
    background-color: #ddd;
}

.tab .tab-button.active {
    font-weight: 500;
    border-bottom: 2px solid #00a2d7;
}

.tabcontent {
    display: block;
    padding: 6px 12px;
    border: 1px solid #ccc;
    border-top: none;
}

/* 
    Meeting background
*/

/* general classes */
.meeting {
    font-family: 'Lato', sans-serif;
}

.bold {
    font-weight: 600;
}

.small-txt {
    font-size: 14px;
}

.arrow-right {
    position: absolute;
    left: 0;
    width: 0;
    height: 0;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;

    border-left: 20px solid #b2b1ae;
}

.bottomMedia {
    bottom: 30px !important;
}

.hide {
    display: none !important;
}

.bgGray {
    background: #808080 !important;
    transition: 0.1s;
}

.hoverIcon {
    /*background: red;*/
    fill: #ff6c11;
}

.activeCtaIcon {
    fill: black;
}

.bgTransparent {
    background: transparent;
}

/* top bar */

.top-bar {
    height: 80px;
    background: #e6e6e4;
}

.company-name {
    font-weight: 600;
    font-size: 16px;
}

.company-name img {
    height: 30px;
    margin-left: 20px;
}

.storage-used {
    text-align: start;
    font-size: 12px !important;
}

.notification {
    position: relative;
}

.push-badge {
    position: absolute;
    bottom: 45%;
    left: 45%;
}

.profile-pic {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    margin-right: 10px;
}

.user {
    display: flex;
    align-items: center;
}

.meeting-url {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 75px;
}

.meeting-url p {
    margin-bottom: 0;
    font-weight: 600;
    font-size: 12px;
    padding-right: 5px;
}

.meeting-url span {
    font-size: 12px;
    margin-right: 10px;
}

/* remote video */

.container-vid {
    /*height: calc(100vh - 75px);*/
    height: calc(100vh - 85px - 75px);
    padding: 0;
}

.localvid {
    position: absolute;
    z-index: 100;
}

.localvid > .localControls {
    left: 50%;
    bottom: 10%;
    margin: -64px 0 0 -64px;
    position: absolute;
    width: 128px;
    z-index: 1;
}

#localVideo {
    height: 250px;
    background-color: black;
}

.remotevid {
    height: 100%;
    background: #232221;
}

/* chat */

.chat {
    position: absolute !important;
    right: calc(100% / 12);
    height: calc(100% - 75px - 98px);
    padding: 30px 15px 50px 15px;
    background: #454545;
    transition: 0.1s background;
    top: 80px;
    bottom: 0;
    width: 30%;
    border-left: 3px solid #e4e4e4;
}

.containerContactList,
.containerParticipantsList {
    position: absolute !important;
    right: calc(100% / 12);
    height: calc(100% - 75px - 75px);
    transition: 0.1s background;

    top: 80px;
    bottom: 0;
    width: 30%;
}

.message-container {
    /*margin-left: 15px;*/
    /*margin-right: 45px;*/
    padding: 10px;
    background: #ffffff;
    border-radius: 10px;
    margin-bottom: 30px;
    width: 100%;
}

.host-message {
    margin-left: 15px;
    margin-right: 45px;
}

.me-message {
    margin-left: 45px;
    margin-right: 15px;
}

.row-msg {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
}

.row-msg .profile {
    display: flex;
    align-items: center;
}

.chat-pic {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 15px;
    background-color: #ff6c11;
    color: white;
    margin-top: 2px;
}

.row-msg h4 {
    font-size: 20px;
    margin: 0;
}

.row-msg span {
    font-size: 20px;
}

.message {
    text-align: start;
}

.chat-input input {
    position: absolute;
    bottom: 50px;
    left: 0;
    border-radius: 6px;
    height: 35px;
    border: 1px solid lightgray;
    margin: 0 30px;
    width: 82%;
}

/* chat side bar */

.chat-sidebar {
    position: absolute !important;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
    top: 45px;
    height: calc(100vh - 75px - 98px);
    padding-top: 33px;
}

.chat-icon-container {
    display: flex;
    justify-content: center;
    align-items: center;

    top: -10px;
    height: 40px;
    margin-bottom: 24px;
}

.chat-profile-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.chat-sidebar-pic {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

/* media */

.media {
    position: relative;
    bottom: 179px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    z-index: 1;
}

.gallery-content {
    width: 100%;
    display: flex;
    padding: 15px 45px;
    background: #dddddd;
}

.media-content-container {
    display: flex;
    align-items: center;
    margin-right: 45px;
    height: 125px;
}

.plus-icon {
    width: 50px;
    height: 50px;
    color: #ffffff;
}

.toggle-gallery {
    width: 100%;
    text-align: start;
    background: #e4e4e4;
    color: #60605d;
    padding-left: 15px;
}

.toggle-gallery span {
    padding-left: 15px;
}

/* bottom bar */

.zoom-step {
    padding: 3px;
    background: #ffffff;
    border-radius: 50%;
    width: 30px;
    height: 30px;
}

.zoom-levels {
    display: flex;
    justify-content: space-between;
}

.zoom-level {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.zoom-level:not(:last-child) {
    margin-right: 15px;
}

.zoom-level p {
    margin-bottom: 0;
    color: #808080;
    font-size: 12px;
}

.circle-zoom {
    width: 17px;
    height: 17px;
    background: #808080;
    border-radius: 50%;
    border: 1px solid black;
    cursor: unset;
}

.zoomActive {
    background: #9dd535;
    border: 1px solid #ffffff;
}

.zoom {
    width: 180px !important;
}

.bottom-bar {
    display: flex;
    justify-content: space-between;
    background: #454545;
    position: relative;
    z-index: 10;
}

.bottom-bar-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 80px;
}

.btn-container {
    padding: 16px 25px;
    display: flex;
    justify-content: center;
}

.bottom-btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
    margin-right: 50px;
    border-radius: 3px;
    background: #323030;
    color: #ffffff;
    transition: border 0.1s;
    height: 48px;
    width: 100px;
    cursor: unset;
}

.bottom-btn:focus {
    outline: 0;
}

.record-video-btn {
    width: 110px !important;
}

.mb-bottom-btn {
    margin-bottom: -3px;
}

.orange-border {
    border: 1px solid #ff6c11;
}

.orange-border:hover {
    border: 1px solid #ff6c11;
    transition: 0.1s;
}

.no-border-change {
    border: 1px solid #ff6c11 !important;
}

.blue-border {
    border: 1px solid #00a2d7;
}

.yellowBorder {
    border: 1px solid #f2e724 !important;
}

.bottom-btn span {
    font-size: 12px;
}

.zoom-btn {
    width: 25px;
    background: inherit;
    border: 0;
}

.inputMessage {
    border-radius: 15px !important;
}

.full-screen {
    background: inherit;
    border: 0;
    cursor: unset;
}

input[type='textarea'].inputMessage::-webkit-input-placeholder {
    font-size: 1px;
}

.row_chat_input {
    bottom: 25px;
    width: 100%;
}

.display_none {
    display: none;
}

.close-call {
    position: absolute !important;
    right: 11%;
}

#remoteVideo {
    /*width: 111%;*/
    /*height: calc(100% - 0px);*/
    height: calc(100% - 30px);
    width: auto;
}

.pdlr20 {
    padding-left: 20px !important;
    padding-right: 20px !important;
}

/*@media screen and (min-width: 1400px) {*/
/*  #remoteVideo {*/
/*    !*height: unset;*!*/
/*    !*width: 100%;*!*/
/*    height: 97%;*/
/*    width: 100%;*/
/*    !* top: 0; *!*/
/*    !* left: 0; *!*/
/*    !* right: 0; *!*/
/*    !* bottom: 0; *!*/
/*    z-index: -1;*/
/*  }*/

/*  .remoteVideoTablet {*/
/*    width: 100% !important;*/
/*    object-fit: fill !important;*/
/*    height: 118% !important;*/
/*  }*/
/*}*/

.remoteVideoTablet {
    height: 100vh !important;
}

@media screen and (max-width: 1300px) {
    #remoteVideo {
        /*height: calc(100% - 0px);*/
    }
}

.ps {
    width: 100%;
    max-width: 1120px;
    height: inherit;
    overflow: auto;
    overflow-x: hidden;
}

.fullscreenWidth {
    height: 100vh;
}

.changeFillParticipantsList > .b706e920-0d44-4e20-a6e8-325512a56134 {
    fill: #ff6c11 !important;
}

.changeFillContactList > .all {
    fill: #ff6c11 !important;
}

.changeFillChat > .b420800f-7479-4660-ab00-dc8003c84947 {
    fill: #ff6c11 !important;
}

.fillBlackContactList:hover > .all {
    fill: black;
}

.fillBlackParticipantsList:hover > .b706e920-0d44-4e20-a6e8-325512a56134 {
    fill: black;
}

.fillBlackChat:hover > .b420800f-7479-4660-ab00-dc8003c84947 {
    fill: black;
}

.unselectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
}
</style>

<script>
import AlertComponent from '@/components/AlertComponent';
import SpinnerComponent from '@/components/SpinnerComponent';

export default {
    components: { AlertComponent, SpinnerComponent },
    data: function () {
        return {
            nameCompany: 'YourCompanyName',
            email: '',
            password: '',
            passwordFieldType: 'password',
            meetingId: '',
            meetingPasscode: '',
            meetingLink: '',
            displayName: '',
            joinUrl: false,
            userLogged: false,
            showSpinner: false,
            textAlert: '',
            validEmail: null,
            guestMode: true,
            selectedGuestModeTab: 'link',
            user: this.$store.getters.getUserData.display_name,
        };
    },
    computed: {
        msoauthUrl: function () {
            return this.$store.getters.getMediaServerUrl + '/msoauth2?continue=' + window.location.origin;
        },
    },
    methods: {
        changeGuestMode(mode) {
            this.selectedGuestModeTab = mode;
        },
        switchVisibility: function () {
            this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
        },
        tenantLookup: function (onComplete) {
            if (this.$store.getters.getMediaServerUrl) {
                console.log('media server already configured');
                onComplete();
            } else if (this.email && this.password) {
                this.showSpinner = true;

                let inputEmail = this.validateEmail(this.email);
                if (inputEmail) {
                    //this.validEmail = true;
                    console.log(inputEmail);
                    let domain = this.email.substring(this.email.lastIndexOf('@') + 1);

                    console.log(domain);
                    let bodyFormData = new FormData();
                    bodyFormData.append('serial', this.$config.serial);

                    this.$http
                        .get(`${process.env.VUE_APP_LICMAN_API_URL}/tenants?domain=${domain}`, bodyFormData, {
                            headers: { 'Content-Type': 'multipart/form-data' },
                        })
                        .then((response) => {
                            // console.log(response.data);
                            let apiStoreUrl = response.data.tenant.organization.storage_service_url;
                            if (apiStoreUrl !== '' && apiStoreUrl !== this.$store.getters.getMediaServerUrl) {
                                this.$store.dispatch('changeMediaServerUrl', apiStoreUrl);
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                        })
                        .finally(() => onComplete());
                } else {
                    //this.validEmail = false;
                    console.log('not email');
                    onComplete();
                }
            } else {
                onComplete();
            }
        },
        validateEmail: function (email) {
            const re =
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(String(email).toLowerCase());
        },
        toggleJoinSignIn: function () {
            this.guestMode = !this.guestMode;
        },
        getRouteMeetingId: function () {
            if (this.$route.params.meeting_id) {
                this.meetingId = this.$route.params.meeting_id;
                this.meetingPasscode = this.$route.params.meeting_passcode;
                this.joinUrl = true;
                this.storeMeetingData(this.meetingId, this.meetingPasscode);
            } else {
                this.meetingId = '';
                this.joinUrl = false;
            }
        },
        getRouteMeetingLink: function () {
            if (this.$route.query.link) {
                this.meetingLink = this.$route.query.link;
                this.joinUrl = true;
                this.storeMeetingLinkData(this.meetingLink);
            } else {
                this.meetingLink = '';
                this.joinUrl = false;
            }
        },
        userSignIn: function (tenantLookup = true) {
            if (!this.validateSignInForm()) return;

            // FIXME: wait for tenant lookup result and after make login against org's media server
            if (tenantLookup)
                this.tenantLookup(() => {
                    // user auth
                    this.$http
                        .post(`${this.$store.getters.getMediaServerUrl}/api/v1/admin/login`, {
                            username: this.email,
                            password: this.password,
                        })
                        .then((response) => {
                            console.log(response.data.user.access_token);

                            this.storeUserData(this.email, this.password, this.email);
                            this.$store.dispatch('userAccessToken', response.data.user.access_token);
                            this.$store.dispatch('changeStorage', {
                                available: response.data.storage.available,
                                usage: this.formatStorage(response.data.storage.usage),
                            });

                            this.displayName = response.data.user.username;
                            this.userLogged = true;
                            this.showSpinner = false;

                            if (this.joinUrl) this.joinMeeting();
                        })
                        .catch((err) => {
                            this.showSpinner = false;
                            console.log(err);
                            this.textAlert = 'Wrong credentials. <br>';
                        });
                });
        },
        userSignInWithToken: function (token) {
            this.$http
                .post(
                    `${this.$store.getters.getMediaServerUrl}/api/v1/admin/login`,
                    {},
                    {
                        headers: { Authorization: `Bearer ${token}` },
                    }
                )
                .then((response) => {
                    console.log(response.data.user.access_token);

                    this.storeUserData(response.data.user.username, '1234567890', response.data.user.username);
                    this.$store.dispatch('userAccessToken', response.data.user.access_token);
                    this.$store.dispatch('changeStorage', {
                        available: response.data.storage.available,
                        usage: this.formatStorage(response.data.storage.usage),
                    });

                    this.displayName = response.data.user.username;
                    this.userLogged = true;
                    this.showSpinner = false;
                    this.guestMode = false;

                    if (this.joinUrl) this.joinMeeting();
                })
                .catch((err) => {
                    this.showSpinner = false;
                    console.log(err);
                    this.textAlert += 'Invalid token. Please try again.';
                })
                .finally(() => this.$router.push('/start'));
        },
        checkLogged: function () {
            let userData = this.$store.getters.getUserData;
            this.userLogged = userData.email && userData.password;
            this.guestMode = !this.userLogged;
        },
        loadUserLoggedData: function () {
            let userData = this.$store.getters.getUserData;
            this.email = userData.email;
            this.displayName = userData.display_name;
        },
        checkLicense: function (onSucc, onErr) {
            let bodyFormData = new FormData();
            bodyFormData.append('serial', this.$config.serial);

            this.$http
                .post(`${process.env.VUE_APP_LICMAN_API_URL}/device/auth`, bodyFormData, {
                    headers: { 'Content-Type': 'multipart/form-data' },
                })
                .then((response) => {
                    console.log(response.data);
                    this.$store.dispatch('changeMediaServerUrl', response.data.storage_service_url);
                    // console.log(this.$store.getters.getMediaServerUrl);
                    onSucc();
                })
                .catch((err) => onErr(err));
        },
        storeUserData: function (email, password, display_name) {
            this.$store.dispatch('changeUser', { email, password, display_name });
            return true;
        },
        storeGuestData: function (displayName) {
            if (!displayName) {
                this.textAlert = 'Enter your name';
                return false;
            }
            this.storeUserData('', '', displayName);
            return true;
        },
        storeMeetingLinkData: function (link) {
            if (!link) {
                this.textAlert = 'Enter a Teams meeting link';
                return false;
            }
            let decodedLink = decodeURIComponent(link);
            this.meetingLink = decodedLink.replace('dl/launcher/launcher.html?url=/_#/', '');
            this.displayName = this.$store.getters.getUserData.display_name;
            let thread_id = this.extractThreadIdFromMeetingLink(link);

            this.$store.dispatch('changeMeeting', { join_url: link, thread_id });
            return true;
        },
        storeMeetingData: function (id, passcode) {
            if (!id || !passcode) {
                this.textAlert = 'Enter meeting id and passcode';
                return false;
            }
            this.meetingId = id;
            this.meetingPasscode = passcode;
            this.displayName = this.$store.getters.getUserData.display_name;

            this.$store.dispatch('changeMeeting', { id, passcode });
            return true;
        },
        validateMeetingData: function () {
            return this.selectedGuestModeTab === 'link' ? this.meetingLink : this.meetingId && this.displayName;
        },
        joinMeeting: function () {
            if (!this.validateMeetingData()) {
                this.textAlert = 'Meeting data missing';
                return;
            }

            window.location.href =
                this.selectedGuestModeTab === 'link'
                    ? `/meeting?link=${this.meetingLink}`
                    : `/meeting/${this.meetingId}/${this.meetingPasscode}`;
        },
        validateSignInForm() {
            if (!this.email) {
                this.textAlert = 'Email is a mandatory field. <br>';
                return false;
            } else if (!this.password) {
                this.textAlert = 'Password is a mandatory field. <br>';
                return false;
            } else if (!this.validateEmail(this.email)) {
                this.textAlert = 'Wrong email format. <br>';
                return false;
            }

            return true;
        },
        formatStorage(val) {
            return Math.round(val * 100);
        },
        logout: function () {
            this.$store.commit('resetUserSessionData');
            this.userLogged = false;
            this.guestMode = true;
        },
    },
    watch: {
        textAlert: function (val) {
            if (val !== '') setTimeout(() => (this.textAlert = ''), 5000);
        },
    },
    beforeMount() {},
    mounted() {
        if (this.$route.query.token) {
            this.userSignInWithToken(this.$route.query.token);
        }

        this.checkLogged();
        this.getRouteMeetingId();
        this.getRouteMeetingLink();
        this.checkLicense(
            () => console.log(),
            (e) => console.log(e)
        );

        if (this.userLogged && this.joinUrl) {
            this.loadUserLoggedData();
            this.joinMeeting();
        }
    },
};
</script>
