<template>
    <div class="meeting">
        <alert-component :type="alertType" :text="textAlert" v-if="textAlert"></alert-component>
        <!--        <alert-component type="success" :text="textAlert"></alert-component>-->
        <!--        <spinner-component></spinner-component>-->
        <b-container fluid style="padding-left: 0; padding-right: 0; overflow: hidden">
            <b-row class="top-bar" align-v="center" :class="{ 'sr-only': fullscreenVideo }">
                <!-- top bar - header -->
                <b-col cols="2" class="company-name">
                    <img src="../assets/fre_logo.png" />
                </b-col>
                <b-col cols="3" class="meeting-url">
                    <div v-if="inMeeting">
                        <p>URL:&nbsp;</p>
                        <div style="display: flex">
                            <span class="truncate">{{ fusionRemoteMeetingUrl }}</span>
                            <inline-svg :src="require('../assets/link_to_url.svg')" width="15" height="15" class="copy" @click="copy" />
                        </div>
                    </div>
                </b-col>
                <b-col cols="3" class="storage-used small-txt">
                    Storage Used: {{ valueStorageUsed }}% of {{ maxGb }}GB
                    <b-progress
                        :value="valueStorageUsed"
                        height="5px"
                        variant="info"
                        style="background: #c8c8c7; max-width: 210px"
                    ></b-progress>
                </b-col>
                <b-col cols="3">
                    <b-row align-v="center">
                        <b-col cols="12" class="user" style="display: flex">
                            <img class="profile-pic" src="https://picsum.photos/25/25" alt="" />
                            <span style="font-size: 14px">{{ user }}</span>
                            <inline-svg
                                :src="require('../assets/caller_log_out.svg')"
                                width="15"
                                height="15"
                                class="ml-2"
                                @click="logout"
                            />
                        </b-col>
                    </b-row>
                </b-col>
                <b-col cols="0" no-gutters style="position: absolute; right: 0; padding-right: 20px; padding-left: 20px">
                    <div class="chat-icon-container" style="margin-bottom: 0; margin-right: 0px">
                        <div class="svg-icon">
                            <inline-svg
                                :src="require('../assets/hang_up.svg')"
                                width="48"
                                height="48"
                                @mouseover="toggleHoverIcon()"
                                @mouseout="toggleHoverIcon()"
                                @click="closeMeetingRedirect()"
                                :class="{ hoverIcon: isHoveringIcon, activeCtaIcon: activeCtaSidebar == 1 }"
                            />
                        </div>
                    </div>
                </b-col>
            </b-row>
            <!-- Cover all ui layout, except top-bar -->
            <!-- <div v-if="showCoverPage" class="cover-page"></div> -->
            <b-row no-gutters>
                <b-col class="container-vid" v-bind:cols="hideChat ? 12 : 12" :class="{ fullscreenWidth: fullscreenVideo }">
                    <div v-if="showStateMessage" class="state-message" style="color: azure">{{ stateMessageText }}</div>
                    <div id="localVideo" class="localvid" :class="{ 'sr-only': fullscreenVideo }">
                        <!-- <video id="localVideo"></video> -->
                        <div class="localControls">
                            <inline-svg
                                :src="selfAudioMuted ? require('../assets/audioMute.svg') : require('../assets/audioUnMute.svg')"
                                width="40"
                                height="40"
                                @click="setSelfAudioMuted(!selfAudioMuted)"
                                class="mr-3"
                            />
                            <inline-svg
                                :src="selfVideoMuted ? require('../assets/videoMute.svg') : require('../assets/videoUnMute.svg')"
                                width="40"
                                height="40"
                                @click="setSelfVideoMuted(!selfVideoMuted)"
                            />
                        </div>
                    </div>
                  
                    <div id="annotationTool" class="annotation-tool">
                        <annotation-component ref="annotationCanvas" v-if="annotation"></annotation-component>
                    </div>
                   
                    <div id="remoteVideosGallery" class="remotevid grid-container" :class="{ remoteVideoTablet: fullscreenVideo }">
                        
                        <!-- <div id="remoteVideo" :class="{ remoteVideoTablet: fullscreenVideo }"></div>
            <audio id="remoteAudio"></audio> -->
                    </div>
                    <div class="remotevid alone-message" v-if="inMeeting && aloneMessage">{{ aloneMessage}}</div>
                    <div id="remoteVideoPinned" class="remotevid hidden" :class="{ remoteVideoTablet: fullscreenVideo }"></div>

                    <div class="media" :class="{ bottomMedia: !bottomMedia, hide: fullscreenVideo }">
                        <div class="toggle-gallery" @click="isLogged ? toggleMedia() : null">
                            <b-icon icon="chevron-up"></b-icon>
                            <span>GALLERY</span>
                        </div>
                        <div class="gallery-content" :class="{ hide: !bottomMedia }">
                            <gallery-component
                                ref="galleryComponent"
                                :hideChat="hideChat"
                                @fileUploaded="fileUploaded"
                                @fileSent="fileSent"
                                v-if="loadGallery"
                            ></gallery-component>
                        </div>
                    </div>
                </b-col>
                <div class="containerContactList" style="width: 30%" v-if="!hideContactList">
                    <contact-list-component
                        @startMeeting="startMeeting"
                        @inviteContacts="inviteContacts"
                        @showErrorEmailAlert="showErrorEmailAlert"
                    ></contact-list-component>
                </div>
                <div class="containerParticipantsList" :class="{ display_none: hideParticipantsList }">
                    <participants-list-component :participants="participants"></participants-list-component>
                </div>
                <div class="chat" id="chat" :class="{ bgGray: bottomMedia, 'sr-only': hideChat }">
                    <!-- chat -->
                    <!--                    <perfect-scrollbar>-->
                    <!--                        <div id="chat">-->
                    <!--                        </div>-->
                    <!--                    </perfect-scrollbar>-->
                    <perfect-scrollbar ref="chat_scroll">
                        <div class="messages" v-for="(message, idx) in messages" :key="idx">
                            <div class="container">
                                <div class="row">
                                    <div class="message-container">
                                        <div class="container">
                                            <div class="row">
                                                <div class="col-2">
                                                    <div class="chat-pic">
                                                        <p style="padding: 4px" v-if="message.senderName">
                                                            {{ message.senderName.substring(0, 1).toUpperCase() }}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div
                                                    class="col-6 text-left mt-1"
                                                    style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis"
                                                >
                                                    {{ message.senderName }}
                                                </div>
                                                <div class="col-4 text-right mt-1" v-if="message.timestamp">
                                                    {{ formatTimestamp(message.timestamp) }}
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-2"></div>
                                                <div
                                                    class="col-10 text-left"
                                                    style="word-wrap: break-word"
                                                    v-if="message.messageText"
                                                    v-html="replaceDownloadUrlsInTextMessage(message.messageText)"
                                                ></div>
                                            </div>
                                            <div class="row" v-for="media in message.medias" :key="media.id">
                                                <img :src="media.previewUrl" alt=""/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </perfect-scrollbar>
                    <div class="chat-input">
                        <b-row class="position-absolute row_chat_input">
                            <b-col xl="10">
                                <b-form-textarea
                                    id="textarea-large"
                                    size="md"
                                    placeholder="Start writing"
                                    class="inputMessage mt-3"
                                    v-model="input_chat_message"
                                    no-resize
                                    @keydown.enter.exact.prevent="
                                        sendMessage(input_chat_message);
                                        clearTextareaMessage();
                                    "
                                ></b-form-textarea>
                            </b-col>
                            <b-col xl="2" class="mt-4" style="padding-left: unset">
                                <inline-svg
                                    :src="require('../assets/send_solid_white.svg')"
                                    width="40"
                                    height="40"
                                    @click="
                                        sendMessage(input_chat_message);
                                        clearTextareaMessage();
                                    "
                                />
                            </b-col>
                        </b-row>
                    </div>
                </div>
                <b-col class="chat-sidebar pdlr20" cols="0" :style="contentSharingIsFullscreen ? 'z-index: 100' : 'z-index: 0'">
                    <!-- sidebar chat -->
                    <div class="arrow-right hide"></div>

                    <div class="cta">
                        <div class="chat-icon-container" @click="showContactList()">
                            <div class="svg-icon">
                                <inline-svg
                                    :src="add_people_svg"
                                    width="48"
                                    height="48"
                                    @click="
                                        changeActiveCtaSidebar(4);
                                        hideChatCta();
                                        closeAll(2);
                                    "
                                    :class="{ changeFillContactList: !hideContactList }"
                                    class="fillBlackContactList"
                                />
                            </div>
                        </div>
                        <div class="chat-icon-container" @click="inMeeting ? showParticipantsList() : null">
                            <div class="svg-icon">
                                <inline-svg
                                    :src="group_number_svg"
                                    width="48"
                                    height="48"
                                    @click="
                                        changeActiveCtaSidebar(5);
                                        hideChatCta();
                                        closeAll(3);
                                    "
                                    :class="{ changeFillParticipantsList: !hideParticipantsList }"
                                    class="fillBlackParticipantsList"
                                />
                            </div>
                        </div>
                        <div class="chat-icon-container" @click="inMeeting ? toggleChat() : null">
                            <div class="svg-icon">
                                <inline-svg
                                    :src="chat_svg"
                                    width="48"
                                    height="48"
                                    @click="inMeeting ? clickIconChat() : null"
                                    :class="{ changeFillChat: !hideChat }"
                                    class="fillBlackChat"
                                />
                            </div>
                        </div>
                        <div class="chat-icon-container display_none">
                            <div class="svg-icon">
                                <inline-svg
                                    :src="require('../assets/call.svg')"
                                    width="40"
                                    height="40"
                                    @mouseover="toggleHoverIcon()"
                                    @mouseout="toggleHoverIcon()"
                                    @click="
                                        changeActiveCtaSidebar(3);
                                        hideChatCta();
                                    "
                                />
                            </div>
                        </div>
                    </div>

                    <div class="chat-profile-container sr-only">
                        <img class="chat-sidebar-pic" src="https://picsum.photos/48/48" alt="" />
                        <span :class="{ hide: hideChat }">{{ user }}</span>
                    </div>
                </b-col>
            </b-row>

            <b-row
                class="bottom-bar"
                no-gutters
                v-if="fullscreenVideo"
                style="background: transparent; position: absolute; right: 33px; bottom: 18px"
            >
                <inline-svg :src="require('../assets/to_shrink.svg')" width="38" @click="inMeeting ? fullscreen() : null" />
            </b-row>
            <b-row class="bottom-bar" no-gutters v-if="!fullscreenVideo">
                <div class="bottom-bar-container">
                    <div class="btn-container"></div>
                    <div class="btn-container">
                        <div class="bottom-btn orange-border zoom">
                            <div class="zoom-levels">
                                <div class="zoom-level">
                                    <p>1x</p>
                                    <div
                                        class="circle-zoom"
                                        @click="inMeeting && isLogged ? changeZoomLevel(1) : null"
                                        :class="{ zoomActive: activeZoom >= 1 }"
                                    ></div>
                                </div>
                                <div class="zoom-level">
                                    <p>2x</p>
                                    <div
                                        class="circle-zoom"
                                        @click="inMeeting && isLogged ? changeZoomLevel(2) : null"
                                        :class="{ zoomActive: activeZoom >= 2 }"
                                    ></div>
                                </div>
                                <div class="zoom-level">
                                    <p>3x</p>
                                    <div
                                        class="circle-zoom"
                                        @click="inMeeting && isLogged ? changeZoomLevel(3) : null"
                                        :class="{ zoomActive: activeZoom >= 3 }"
                                    ></div>
                                </div>
                                <div class="zoom-level">
                                    <p>4x</p>
                                    <div
                                        class="circle-zoom"
                                        @click="inMeeting && isLogged ? changeZoomLevel(4) : null"
                                        :class="{ zoomActive: activeZoom >= 4 }"
                                    ></div>
                                </div>
                                <div class="zoom-level">
                                    <p>5x</p>
                                    <div
                                        class="circle-zoom"
                                        @click="inMeeting && isLogged ? changeZoomLevel(5) : null"
                                        :class="{ zoomActive: activeZoom >= 5 }"
                                    ></div>
                                </div>
                            </div>
                            <span>Zoom</span>
                        </div>
                        <button class="bottom-btn orange-border toggle-light" @click="inMeeting && isLogged ? ControlsFlashlight() : null">
                            <inline-svg
                                :src="statusLight ? require('../assets/lights_active.svg') : require('../assets/lights_default.svg')"
                                width="34"
                            />
                            <div class="mb-bottom-btn"><span v-if="!statusLight">Lights ON</span></div>
                            <div class="mb-bottom-btn"><span v-if="statusLight">Lights OFF</span></div>
                        </button>
                        <button class="bottom-btn orange-border take-photo" @click="inMeeting && isLogged ? ControlsTakeFoto() : null">
                            <inline-svg
                                :src="takePhoto ? require('../assets/camera_active.svg') : require('../assets/camera_default.svg')"
                                width="34"
                            />
                            <div class="mb-bottom-btn"><span>TAKE Photo</span></div>
                        </button>
                        <button
                            class="bottom-btn orange-border toggle-video record-video-btn"
                            @click="inMeeting && isLogged ? ControlsRecordVideo() : null"
                        >
                            <inline-svg
                                :src="recordVideo ? require('../assets/video_active.svg') : require('../assets/video_default.svg')"
                                width="37"
                            />
                            <div class="mb-bottom-btn"><span>RECORD Video</span></div>
                        </button>
                        <button
                            class="bottom-btn parent blue-border toggle-video"
                            @click="inMeeting ? toggleStartStopScreenShare() : null"
                            :class="{ yellowBorder: selfContentSharingIsActive === true }"
                        >
                            <inline-svg
                                :src="
                                    selfContentSharingIsActive
                                        ? require('../assets/desktop_active.svg')
                                        : require('../assets/desktop_inactive.svg')
                                "
                                width="48"
                            />
                            <div class="mb-bottom-btn"><span>Screen Share</span></div>
                        </button>
                        <button
                            class="bottom-btn parent blue-border toggle-video"
                            @click="inMeeting && isLogged ? ControlsAnnotations() : null"
                            :class="{ yellowBorder: annotation === true }"
                        >
                            <inline-svg
                                :src="annotation ? require('../assets/annotation_active.svg') : require('../assets/annotation_default.svg')"
                                width="38"
                            />
                            <div class="mb-bottom-btn"><span>Annotations</span></div>
                        </button>

                        <!-- annotation tools -->
                        <button
                            v-if="annotation"
                            class="bottom-btn child blue-border toggle-video"
                            alt="line"
                            @click="setAnnotationActiveTool('line')"
                            :class="{ yellowBorder: annotation === true && annotation_active_tool === 'line' }"
                        >
                            <inline-svg :src="require('../assets/pencil.svg')" fill="#fff" width="25" />
                        </button>
                        <button
                            v-if="annotation"
                            class="bottom-btn child blue-border toggle-video"
                            alt="pointer"
                            @click="setAnnotationActiveTool('pointer')"
                            :class="{ yellowBorder: annotation === true && annotation_active_tool === 'pointer' }"
                        >
                            <inline-svg :src="require('../assets/pointer.svg')" fill="#fff" width="25" />
                        </button>
                        <!-- /annotation tools -->
                    </div>
                    <div class="btn-container" style="padding: 16px 20px" v-bind:style="{ opacity: fullscreenRemoteVideoButtonVisibility }">
                        <button class="full-screen" @click="inMeeting ? fullscreen() : null">
                            <inline-svg
                                :src="fullscreenVideo ? require('../assets/shrink-enlarge-minus.svg') : require('../assets/to_enlarge.svg')"
                                width="48"
                            />
                        </button>
                    </div>
                </div>
            </b-row>
        </b-container>
    </div>
</template>

<script>
import $ from 'jquery';
import AlertComponent from '@/components/AlertComponent';
// import SpinnerComponent from "@/components/SpinnerComponent";
import GalleryComponent from '@/components/GalleryComponent';
import ContactListComponent from '@/components/ContactListComponent';
import ParticipantsListComponent from '@/components/ParticipantsListComponent';
import AnnotationComponent from '@/components/AnnotationComponent';
// Azure communication services (ACS)
import { AzureCommunicationTokenCredential } from '@azure/communication-common';
//import { CommunicationIdentityClient } from "@azure/communication-identity";
/* import { AzureLogger, setLogLevel } from '@azure/logger'; */
import { CallClient, VideoStreamRenderer, LocalVideoStream } from '@azure/communication-calling';
import { ChatClient } from '@azure/communication-chat';

export default {
    // components: {SpinnerComponent},
    components: { GalleryComponent, ContactListComponent, ParticipantsListComponent, AlertComponent, AnnotationComponent },

    data: function () {
        return {
            bjn: null,
            selfVideoMuted: true,
            selfAudioMuted: false,
            availableCameras: [],
            currentCamera: 0,
            isReceivingContentShare: false,
            contentSharingVideoVisibility: 'none',
            selfContentSharingIsActive: false,
            contentSharingInterval: null,
            contentSharingIsFullscreen: false,
            fullscreenRemoteVideoButtonVisibility: 1,
            remoteVideoPinnedID: null,

            /* --Azure Communication Service-- */
            callAgent: null,
            chatClient: null,
            chatThreadClient: null,
            threadId: '',
            userId: null,
            incomingCall: null,
            deviceManager: null,
            call: null,
            localVideoStreamRenderer: null,
            localVideoStream: null,
            meetingLink: '',

            /* ---- */

            stateMessageText: '',
            aloneMessage: 'No one is sharing video',
            nameCompany: 'Jujo Inc.',
            status: 'Active',
            credential: 'Skype for business',
            valueStorageUsed: '',
            maxGb: '',
            user: this.$store.getters.getUserData.display_name,
            isLogged: this.$store.getters.isLogged,
            isActive: null,
            badgeNumber: null,
            badgeActive: null,
            bottomMedia: false,
            hideChat: true,
            activeZoom: 1,
            activeCtaSidebar: null,
            isHoveringIcon: false,
            textAlert: '',
            alertType: 'success',
            muteVideo: false,
            muteAudio: false,
            statusLight: false,
            takePhoto: false,
            recordVideo: false,
            input_chat_message: '',
            hideContactList: true,
            disableGallery: true,
            hideParticipantsList: true,
            countParticipants: null,
            participants: [],
            loadGallery: false,
            inMeeting: this.$store.getters.inMeeting != '' ? true : false,
            fullscreenVideo: false,
            fusionRemoteMeetingUrl: '',
            annotation: false,
            annotation_active_tool: 'line',

            // Chat
            messages: [],

            // Link svg
            add_people_svg: require('../assets/add_people_v2_grey.svg'),
            group_number_svg: require('../assets/group_number_grey.svg'),
            chat_svg: require('../assets/chat_grey.svg'),
        };
    },
    sockets: {
        connect: function () {
            console.log('socket connected');
            this.emitEventRegister();

            if (this.inMeeting) {
                this.emitEventJoinMeetingRoom();
            }
        },
        disconnect: function () {
            console.log('socket disconnected');
        },
        receive: function (data) {
            if (data.error) {
                this.textAlert = data.error;
                this.alertType = 'error';
            }

            if (data.action == 'start_meeting') {
                var id = data.meeting.id;
                var passcode = data.meeting.passcode;
                var join_url = data.meeting.join_url;
                var thread_id = data.meeting.thread_id;

                this.$store.dispatch('changeMeeting', { id, passcode, join_url, thread_id });

                if (id && passcode) {
                    window.location = `/meeting/${id}/${passcode}`;
                }

                if (join_url) {
                    window.location = `/meeting?link=${join_url}`;
                }
            }

            /* if (data.action == 'start_meeting') {
        var id = data.meeting_id;
        var passcode = data.meeting.attendeePasscode;
        var link = data.meeting.link;
        var thread_id = data.meeting.thread_id;

        this.$store.dispatch("changeMeeting", { id, passcode, link, thread_id });

        if (id && passcode) {
          window.location = `/meeting/${id}/${passcode}`;
        }

        if (link) {
          window.location = `/meeting?link=${link}`;
        }
      } */
        },
    },
    methods: {
        // ACS
        async initACS() {
            //console.log('init ACS with token:', token);

            const token = await this.getAcsClientToken();

            // Call client
            const callClient = new CallClient();
            //let tokenCredential = new AzureCommunicationTokenCredential(this.getAcsUserToken());
            const displayName = this.$store.getters.getUserData.display_name;
            this.callAgent = await callClient.createCallAgent(
                new AzureCommunicationTokenCredential(token),
                { displayName: displayName }
            );

            // Chat client
            this.chatClient = new ChatClient(process.env.VUE_APP_TEAMS_MESSAGE_ENDPOINT, new AzureCommunicationTokenCredential(token));
            console.log('chat client', this.chatClient);

            // Subscribe chat
            this.subscribeChat();

            // Set thread id
            this.threadId = this.extractThreadIdFromMeetingLink(this.meetingLink);

            // Chat thread client
            this.chatThreadClient = this.chatClient.getChatThreadClient(this.threadId);
            console.log('chat client 2', this.chatThreadClient);

            // Set up a camera device to use.
            this.deviceManager = await callClient.getDeviceManager();
            await this.deviceManager.askDevicePermission({ video: true });
            await this.deviceManager.askDevicePermission({ audio: true });

            // Listen for an incoming call to accept.
            this.callAgent.on('incomingCall', async (args) => {
                try {
                    this.incomingCall = args.incomingCall;
                    console.log('INCOMING CALL', this.incomingCall);
                    //acceptCallButton.disabled = false;
                    //startCallButton.disabled = true;
                } catch (error) {
                    console.error(error);
                }
            });
        },

        /* initACSChat(token) {
            let tokenCredential = new AzureCommunicationTokenCredential(token);

            // Init Chat Client
            this.chatClient = new ChatClient(process.env.VUE_APP_TEAMS_MESSAGE_ENDPOINT, tokenCredential);

            console.log('chat client', this.chatClient);

            // Set thread id
            this.threadId = this.extractThreadIdFromMeetingLink(this.meetingLink);

            // Chat thread client
            this.chatThreadClient = this.chatClient.getChatThreadClient(this.threadId);
            console.log('chat client 2', this.chatThreadClient);

            // Subscribe chat events
            this.subscribeChat();
        }, */

        /**
         * Join meeting
         */
        async joinMeeting() {
            this.stateMessageText = 'Connecting...';

            const locator = {
                meetingLink: this.meetingLink,
            };
            this.call = this.callAgent.join(locator);
            this.subscribeToCall();

            setTimeout(() => {
                this.disableGallery = false;
                this.$store.getters.isLogged ? (this.loadGallery = true) : (this.loadGallery = false);

                if (this.$store.getters.isLogged) {
                    this.emitEventJoinMeetingRoom();
                }
            }, 3000);
        },

        /**
         * Chat thread
         */
        /* async createChatThread() {
            const createChatThreadRequest = {
                topic: 'Message test',
            };
            const createChatThreadOptions = {
                participants: [
                    {
                        id: { communicationUserId: '8:acs:fbcf9305-f218-4d6d-b229-9407f59b142c_0000001b-1551-c325-3dfe-9c3a0d006376' },
                        displayName: 'eddy expert',
                    },
                ],
            };
            const createChatThreadResult = await this.chatClient.createChatThread(createChatThreadRequest, createChatThreadOptions);
            const threadId = createChatThreadResult.chatThread.id;
            console.log('THREAD IDD', threadId);
            return threadId;
        }, */
        /**
         * Subscribe to a call obj.
         * Listen for property changes and collection updates.
         */
        subscribeToCall() {
            try {
                // Inspect the initial call.id value.
                console.log(`Call Id: ${this.call.id}`);

                //Subscribe to call's 'idChanged' event for value changes.
                this.call.on('idChanged', () => {
                    console.log(`Call Id changed: ${this.call.id}`);
                });

                // Inspect the initial call.state value.
                console.log(`Call state: ${this.call.state}`);
                // Subscribe to call's 'stateChanged' event for value changes.
                this.call.on('stateChanged', async () => {
                    console.log(`Call state changed: ${this.call.state}`);
                    this.stateMessageText = this.call.state;
                    /*
                    if (this.call.state === 'Connected') {
                        console.log('TEST CONNECTED');
                        this.stateMessageText = '';
                        //connectedLabel.hidden = false;
                        //acceptCallButton.disabled = true;
                        //startCallButton.disabled = true;
                        //hangUpCallButton.disabled = false;
                        //startVideoButton.disabled = false;
                        //stopVideoButton.disabled = false;
                        //remoteVideosGallery.hidden = false;
                    } else if (this.call.state === 'Disconnected') {
                        this.stateMessageText = 'Disconnected';
                        //connectedLabel.hidden = true;
                        //startCallButton.disabled = false;
                        //hangUpCallButton.disabled = true;
                        //startVideoButton.disabled = true;
                        //stopVideoButton.disabled = true;
                        console.log(
                            `Call ended, call end reason={code=${this.call.callEndReason.code}, subCode=${this.call.callEndReason.subCode}}`
                        );
                    } else if (this.call.state === 'Disconnecting') {
                        this.stateMessageText = 'Disconnecting...';
                    } else if (this.call.state === 'InLobby') {
                        this.stateMessageText = 'In lobby';
                        //connectedLabel.hidden = true;
                        //startCallButton.disabled = false;
                        //hangUpCallButton.disabled = true;
                        //startVideoButton.disabled = true;
                        //stopVideoButton.disabled = true;
                        console.log(`Call in lobby`);
                    
                    } else if (this.call.state === 'Connecting') {
                        this.stateMessageText = 'In lobby';
                        //connectedLabel.hidden = true;
                        //startCallButton.disabled = false;
                        //hangUpCallButton.disabled = true;
                        //startVideoButton.disabled = true;
                        //stopVideoButton.disabled = true;
                        console.log(`Call in lobby`);
                    }
                    */
                });

                // Is local video started changed
                this.call.on('isLocalVideoStartedChanged', () => {
                    console.log(`isLocalVideoStarted changed: ${this.call.isLocalVideoStarted}`);
                });
                console.log(`isLocalVideoStarted: ${this.call.isLocalVideoStarted}`);
                this.call.localVideoStreams.forEach(async (lvs) => {
                    this.localVideoStream = lvs;
                    console.log('LOCAL VIDEO STREAM', this.localVideoStream);
                    let video = await this.displayLocalVideoStream();

                    let localVideo = document.getElementById('localVideo');
                    localVideo.appendChild(video);
                });

                // Local video stream updated
                this.call.on('localVideoStreamsUpdated', (e) => {
                    e.added.forEach(async (lvs) => {
                        if (lvs.mediaStreamType !== 'ScreenSharing') {
                            this.localVideoStream = lvs;
                            let video = await this.displayLocalVideoStream();

                            let localVideo = document.getElementById('localVideo');
                            localVideo.appendChild(video);
                        }
                    });
                    e.removed.forEach((lvs) => {
                        if (lvs.mediaStreamType !== 'ScreenSharing') {
                            this.removeLocalVideoStream();
                        }
                    });
                });

                // Inspect the call's current remote participants and subscribe to them.
                this.call.remoteParticipants.forEach((remoteParticipant) => {
                    this.subscribeToRemoteParticipant(remoteParticipant);
                });
                // Subscribe to the call's 'remoteParticipantsUpdated' event to be
                // notified when new participants are added to the call or removed from the call.
                this.call.on('remoteParticipantsUpdated', (e) => {
                    // Subscribe to new remote participants that are added to the call.
                    e.added.forEach((remoteParticipant) => {
                        this.subscribeToRemoteParticipant(remoteParticipant);
                        this.participants.push(remoteParticipant);
                        this.countParticipants = this.participants.length;
                        $('#countOfParticipants').html(this.countParticipants);
                    });
                    // Unsubscribe from participants that are removed from the call
                    e.removed.forEach((remoteParticipant) => {
                        console.log(remoteParticipant);
                        console.log('Remote participant removed from the call.');

                        const removeParticipant = (arr, value) => {
                            return arr.filter((participant) => {
                                return participant.identifier.rawId !== value.identifier.rawId;
                            });
                        };

                        this.participants = removeParticipant(this.participants, remoteParticipant);
                        this.countParticipants = this.participants.length;
                        $('#countOfParticipants').html(this.countParticipants);
                    });

                    // Remote participants message state
                    this.stateMessageText =
                        this.countParticipants == 0 && this.call.state === 'Connected' ? 'You are alone in this call' : '';
                });

                // Screen sharing can also be stoped by clicking on the native browser's "Stop sharing" button.
                // The isScreenSharingOnChanged event will be triggered where you can check the value of call.isScreenSharingOn.
                // If the value is false, then that means screen sharing is turned off and so we can go ahead and dispose the screen share preview.
                // This event is also triggered for the case when stopping screen sharing via Call.stopScreenSharing() API.
                this.call.on('isScreenSharingOnChanged', () => {
                    if (!this.call.isScreenSharingOn) {
                        this.selfContentSharingIsActive = false;
                    } else {
                        this.selfContentSharingIsActive = true;
                    }
                });
            } catch (error) {
                console.error(error);
            }
        },

        /**
         * Subscribe to a remote participant obj.
         * Listen for property changes and collection udpates.
         */
        subscribeToRemoteParticipant(remoteParticipant) {
            try {
                // Inspect the initial remoteParticipant.state value.
                console.log(`Remote participant state: ${remoteParticipant.state}`);
                // Subscribe to remoteParticipant's 'stateChanged' event for value changes.
                remoteParticipant.on('stateChanged', () => {
                    console.log(`Remote participant state changed: ${remoteParticipant.state}`);
                });

                // Inspect the remoteParticipants's current videoStreams and subscribe to them.
                remoteParticipant.videoStreams.forEach((remoteVideoStream) => {
                    this.subscribeToRemoteVideoStream(remoteVideoStream);
                });
                // Subscribe to the remoteParticipant's 'videoStreamsUpdated' event to be
                // notified when the remoteParticiapant adds new videoStreams and removes video streams.
                remoteParticipant.on('videoStreamsUpdated', (e) => {
                    // Subscribe to new remote participant's video streams that were added.
                    console.log(e, 'test')
                    e.added.forEach((remoteVideoStream) => {
                        this.subscribeToRemoteVideoStream(remoteVideoStream);
                    });
                    // Unsubscribe from remote participant's video streams that were removed.
                    e.removed.forEach((remoteVideoStream) => {
                        console.log(remoteVideoStream);
                        console.log('Remote participant video stream was removed.');
                    });
                });
            } catch (error) {
                console.log(error);
                console.error(error);
            }
        },
        /**
         * Subscribe to a remote participant's remote video stream obj.
         * You have to subscribe to the 'isAvailableChanged' event to render the remoteVideoStream. If the 'isAvailable' property
         * changes to 'true', a remote participant is sending a stream. Whenever availability of a remote stream changes
         * you can choose to destroy the whole 'Renderer', a specific 'RendererView' or keep them, but this will result in displaying blank video frame.
         */
        async subscribeToRemoteVideoStream(remoteVideoStream) {
            // Reference to the html's div where we would display a grid of all remote video stream from all participants.
            let remoteVideosGallery = document.getElementById('remoteVideosGallery');
            let remoteVideoPinned = document.getElementById('remoteVideoPinned');

            // Create a video stream renderer for the remote video stream.
            let videoStreamRenderer = new VideoStreamRenderer(remoteVideoStream);
            let view;
            let loadingSpinner = document.createElement('div');

            // See the css example below for styling the loading spinner.
            loadingSpinner.className = 'loading-spinner';

            const createView = async (mediaStreamType) => {
                // Create a renderer view for the remote video stream.
                view = await videoStreamRenderer.createView({ scalingMode: 'Fit' });
                this.aloneMessage = ''
                // Attach the renderer view to the UI.
                view.target.id = 'remoteVideo-' + view._viewId;
                view.target.className = 'remote-video-container grid-item';
                view.target.firstElementChild.style.height = '100%';
                view.target.firstElementChild.id = 'remoteVideo';
                view.target.style.border = '3px solid transparent';
                view.target.style.maxHeight = 'calc(100vh - 30px - 154px)';

                if (mediaStreamType === 'ScreenSharing') {
                    this.isReceivingContentShare = true;
                } 
                
                // Add borders on remote video
                view.target.onmouseenter = () => {
                    view.target.style.border = '3px solid #00a2d7';
                    view.target.style.cursor = 'pointer';
                }

                // Remove borders on remote video
                view.target.onmouseleave = () => {
                    view.target.style.border = '3px solid transparent';
                }

                view.target.onclick = () => {

                    if(this.remoteVideoPinnedID == null) {
                        remoteVideosGallery.classList.add('hidden');
                        remoteVideoPinned.classList.remove('hidden');
                        remoteVideoPinned.appendChild(view.target);
                        this.remoteVideoPinnedID = view._viewId;
                    } else {
                        remoteVideosGallery.classList.remove('hidden');
                        remoteVideosGallery.appendChild(view.target);
                        remoteVideoPinned.classList.add('hidden');
                        this.remoteVideoPinnedID = null;
                    }
                }

                // Append remote video to remote videos gallery
                remoteVideosGallery.appendChild(view.target);    
            };

            // Remote participant has switched video on/off
            remoteVideoStream.on('isAvailableChanged', async (isAvailable) => {
                try {
                    console.log('test stream', isAvailable);
                    if (isAvailable) {
                        await createView(remoteVideoStream.mediaStreamType);
                    } else {
                        if (remoteVideoStream.mediaStreamType === 'ScreenSharing') {
                            this.isReceivingContentShare = false;
                        }

                        if(this.remoteVideoPinnedID == view._viewId) {
                            remoteVideosGallery.classList.remove('hidden');
                            remoteVideosGallery.appendChild(view?.target);
                            remoteVideoPinned.classList.add('hidden');
                            this.remoteVideoPinnedID = null;
                        } 
                        
                        if(this.remoteVideoPinnedID == null) {
                            remoteVideosGallery.removeChild(view?.target);
                        }
                   
                        view.dispose();
                    }
                    //Text when no one share video
                    if(remoteVideosGallery.children.length === 0){
                            this.aloneMessage = 'No one is sharing video';
                         }else { 
                            this.aloneMessage = null;
                        }
                } catch (e) {
                    console.error(e);
                }
            });

            // Remote participant has video on initially.
            if (remoteVideoStream.isAvailable) {
                try {
                    await createView();
                } catch (e) {
                    console.warn(e);
                }
            } 
           
        },
     
        /**
         * Subscribe chat
         */
        async subscribeChat() {
            // subscribe to realTimeNotificationConnected event
            this.chatClient.on('realTimeNotificationConnected', () => {
                console.log('Real time notification is now connected!');
                // your code here
            });

            // subscribe to realTimeNotificationDisconnected event
            this.chatClient.on('realTimeNotificationDisconnected', () => {
                console.log('Real time notification is now disconnected!');
                // your code here
            });

            // open notifications channel
            await this.chatClient.startRealtimeNotifications();

            // subscribe to new message notifications
            this.chatClient.on('chatMessageReceived', (e) => {
                console.log('Notification chatMessageReceived!');

                // check whether the notification is intended for the current thread
                if (this.threadId != e.threadId) {
                    return;
                }

                let message = {
                    timestamp: e.createdOn,
                    senderName: e.senderDisplayName,
                    messageText: e.message,
                    senderIsMe: false,
                    medias: e.attachments
                };

                console.log('message', message);

                // TODO: render my messages layout differently, now my messages and other users messages layouts are equals
                if (e.sender.communicationUserId != this.userId) {
                    // Receive another user message
                    message.senderIsMe = false;
                } else {
                    // Receive my message
                    message.senderIsMe = true;
                }

                let formattedUrl = message.messageText.replace(/<a href="[^"]+">/, '');
                    formattedUrl = formattedUrl.replace(/<\/a>$/, '');
                // Check if the message is a url media
                if (this.checkMediaUrl(formattedUrl)) {
                    // put into the gallery
                    this.$refs.galleryComponent.putMedia({ url: formattedUrl });
                }

                // TODO: manage medias sent from teams client
                if(message.medias.length > 0) {
                    //
                }

                this.messages.push(message);
                this.$refs.chat_scroll.$el.scrollTop = $('#chat .ps')[0].scrollHeight;
            });
        },

        /**
         * Unsubscribe azure events
         */
        unsubscribeEvents() {
            // Call unsubscribes
            this.call.off('stateChanged', () => {});
            this.call.off('idChanged', () => {});
            this.call.off('isLocalVideoStartedChanged', () => {});
            this.call.off('localVideoStreamsUpdated', () => {});
            this.call.off('remoteParticipantsUpdated', () => {});
            this.call.off('isScreenSharingOnChanged', () => {});

            // Remote video unsubscribes
            //this.remoteParticipant.off('videoStreamsUpdated', () => {});

            // Remote video stream unsibscribes
            //this.remoteVideoStream.off('isReceivingChanged', () => {});
            //this.remoteVideoStream.off('isAvailableChanged', () => {});

            //Chat unsubscribes
            this.chatClient.off('realTimeNotificationConnected', () => {});
            this.chatClient.off('realTimeNotificationDisconnected', () => {});
            this.chatClient.off('chatMessageReceived', () => {});
            this.chatClient.stopRealtimeNotifications();
        },

        /**
         * Display your local video stream preview in your UI
         */
        async displayLocalVideoStream() {
            try {
                this.localVideoStreamRenderer = new VideoStreamRenderer(this.localVideoStream);
                const view = await this.localVideoStreamRenderer.createView({ scalingMode: 'Fit' });
                return view.target;
                //localVideoContainer.hidden = false;
                //localVideoContainer.appendChild(view.target);
            } catch (error) {
                console.error(error);
                return null;
            }
        },

        /**
         * Remove your local video stream preview from your UI
         */
        async removeLocalVideoStream() {
            try {
                this.localVideoStreamRenderer.dispose();
                //localVideoContainer.hidden = true;
            } catch (error) {
                console.error(error);
            }
        },

        /**
         * Start screen sharing
         */
        async startScreenSharing() {
            try {
                await this.call.startScreenSharing();
            } catch (error) {
                console.error(error);
            }
        },

        /**
         * Stop screen sharing
         */
        async stopScreenSharing() {
            try {
                await this.call.stopScreenSharing();
            } catch (error) {
                console.error(error);
            }
        },

        /**
         * Start your local video stream.
         * This will send your local video stream to remote participants so they can view it.
         */
        async startVideo() {
            try {
                this.localVideoStream = await this.createLocalVideoStream();
                await this.call.startVideo(this.localVideoStream);
            } catch (error) {
                console.error(error);
            }
        },

        /**
         * Stop your local video stream.
         * This will stop your local video stream from being sent to remote participants.
         */
        async stopVideo() {
            try {
                await this.call.stopVideo(this.localVideoStream);
            } catch (error) {
                console.error(error);
            }
        },

        /**
         * To render a LocalVideoStream, you need to create a new instance of VideoStreamRenderer, and then
         * create a new VideoStreamRendererView instance using the asynchronous createView() method.
         * You may then attach view.target to any UI element.
         */
        async createLocalVideoStream() {
            const camera = (await this.deviceManager.getCameras())[0];
            if (camera) {
                return new LocalVideoStream(camera);
            } else {
                console.error(`No camera device found on the system`);
            }
        },

        /**
         * Place a 1:1 outgoing video call to a user
         * Add an event listener to initiate a call when the `startCallButton` is clicked:
         * First you have to enumerate local cameras using the deviceManager `getCameraList` API.
         * In this quickstart we're using the first camera in the collection. Once the desired camera is selected, a
         * LocalVideoStream instance will be constructed and passed within `videoOptions` as an item within the
         * localVideoStream array to the call method. Once your call connects it will automatically start sending a video stream to the other participant.
         */
        /* async startCall() {
            try {
                this.localVideoStream = await this.createLocalVideoStream();
                const videoOptions = this.localVideoStream ? { localVideoStreams: [this.localVideoStream] } : undefined;
                // TODO: ACS calleeAcsUserId
                this.call = this.callAgent.startCall([{ communicationUserId: 'test@informamuse.com' }], { videoOptions });
                // Subscribe to the call's properties and events.
                this.subscribeToCall();
            } catch (error) {
                console.error(error);
            }
        }, */

        /**
         * Accepting an incoming call with video
         * Add an event listener to accept a call when the `acceptCallButton` is clicked:
         * After subscribing to the `CallAgent.on('incomingCall')` event, you can accept the incoming call.
         * You can pass the local video stream which you want to use to accept the call with.
         */
        async acceptCall() {
            try {
                this.localVideoStream = await this.createLocalVideoStream();
                const videoOptions = this.localVideoStream ? { localVideoStreams: [this.localVideoStream] } : undefined;
                this.call = await this.incomingCall.accept({ videoOptions });
                // Subscribe to the call's properties and events.
                this.subscribeToCall();
            } catch (error) {
                console.error(error);
            }
        },
        ///////
        selectCamera(val) {
            if (this.availableCameras[val]) {
                this.currentCamera = val;
                this.bjn.videoDeviceService.selectCamera(this.availableCameras[val]);
            } else alert('selected camera is not available');
        },
        startObservers() {
            this.bjn.meetingService.observe('connectionState', () => {
                console.info('Connection state is changed to : ', this.bjn.meetingService.connectionState);
            });

            this.bjn.meetingService.participantService.observe('participants', () => {
                this.participants = this.bjn.meetingService.participantService.participants;
                this.countParticipants = this.participants.length;
                $('#countOfParticipants').html(this.countParticipants);
            });

            /* this.bjn.meetingService.publicChatService.observe("chatHistory", () => {
         this.messages = this.bjn.meetingService.publicChatService.chatHistory;
         console.log('messages',this.messages[0]);
         console.info("public chatHistory : ", this.bjn.meetingService.publicChatService.chatHistory);
         //setTimeout(() => $('#chat .ps').animate({ scrollTop: $('#chat .ps')[0].scrollHeight }, 'fast'), 1000);

         this.$refs.chat_scroll.$el.scrollTop = $('#chat .ps')[0].scrollHeight;
       }); */

            this.bjn.meetingService.publicChatService.events.newMessage.subscribe((message) => {
                this.messages.push(message);
                console.log('[chat] public message : ', message);
                console.log($('#chat .ps')[0].scrollHeight);
                this.onReceiveChatMessage(message);
                //this.$refs.chat_scroll.$el.scrollTop = $('#chat .ps')[0].scrollHeight;
                setTimeout(() => {
                    const container = this.$el.querySelector('#chat .ps');
                    container.scrollTop = container.scrollHeight;
                }, 0);
            });

            this.bjn.meetingService.observe('permissionError', () => {
                console.info('Permission Error : ', this.bjn.meetingService.connectionState);
            });
        },
        requestPermissions(ok, ko) {
            this.bjn.permissionService
                .requestAllPermissions(ok, ko)
                .then(() => ok())
                .catch((err) => alert(err));
        },
        /* joinMeeting() {
      if (!this.validateMeetingParams(this.$route.params.meeting_id, this.$route.params.meeting_passcode)) {
        this.textAlert = 'The meeting id may not be valid. Check the access data of the meeting';
        this.alertType = 'error';
        return;
      }

      this.bjn.meetingService.joinMeeting(this.$route.params.meeting_id, this.$route.params.meeting_passcode, this.$store.getters.getUserData.display_name).then(
        () => {
          console.log("Success");
          this.setSelfVideoMuted(false);
          this.setSelfAudioMuted(false);
        },
        (error) => {
          alert("Meeting join failed with error: ", JSON.stringify(error));
        }
      );

      this.localControls = true;

      setTimeout(() => {
        this.disableGallery = false;
        (this.$store.getters.isLogged) ? this.loadGallery = true : this.loadGallery = false;
        //this.countParticipants = window.expMain.getCountParticipant();

        this.participants = this.bjn.meetingService.participantService.participants;
        this.countParticipants = this.participants.length;
        console.log('Participants: ', this.bjn.meetingService.participantService.participants);

        $("#countOfParticipants").html(this.countParticipants);

        if (this.$store.getters.isLogged)
          this.emitEventJoinMeetingRoom();
      }, 3000);
    }, */
        attachVideoStreams() {
            //let localVideo = document.getElementById('localVideo');
            //this.acs.displayLocalVideoStream(localVideo);
        },
        setSelfVideoMuted(val) {
            this.selfVideoMuted = val;
            if (val) {
                this.stopVideo();
            } else {
                this.startVideo();
            }
        },
        setSelfAudioMuted(val) {
            this.selfAudioMuted = val;
            this.selfAudioMuted ? this.call.mute() : this.call.unmute();
            //this.bjn.meetingService.setAudioMuted(val);
        },
        toggleStartStopScreenShare() {
            if (this.call.isScreenSharingOn) {
                this.stopScreenSharing();
            } else {
                this.startScreenSharing();
            }

            /* if(this.bjn.meetingService.contentService.contentShareState == ContentShareState.STARTED ) {
        this.bjn.meetingService.contentService.stopContentShare();
        console.log('stop content sharing');
      } else {
        this.bjn.meetingService.contentService.startContentShare();
        console.log('start content sharing');
      } */
        },
        toggleContentSharingFullscreen() {
            this.contentSharingIsFullscreen = !this.contentSharingIsFullscreen;
        },

        /**
         * Save response token in localStorage
         *
         * @param {any} obj
         */
        saveResponseToken(scope, obj) {
            let data = localStorage.getItem('acs') ? JSON.parse(localStorage.getItem('acs')) : { auth: {} };
            data.auth[scope] = obj;

            localStorage.setItem('acs', JSON.stringify(data));

            // Save payload in localStorage
            /* localStorage.setItem(
                'acs',
                JSON.stringify({
                    ${scope}: {
                        auth: {
                            communicationUserId: obj.communicationUserId,
                            token: obj.token,
                            expiresOn: obj.expiresOn,
                        },
                    },
                })
            ); */
        },

        /* ------- */

        replaceDownloadUrlsInTextMessage(text) {
            //var urlRegex = /(https?:\/\/[^\s]+)/g;
            var urlRegexImage = /(https?:\/\/.*)(:?(\d*))\/?(.(?:jpg|jpeg|gif|png))/g;
            var urlRegexVideo = /(https?:\/\/.*)(:?(\d*))\/?(.(?:mp4))/g;
            var urlRegexDoc = /(https?:\/\/.*)(:?(\d*))\/?(.(?:pdf))/g;


            try {
                // Check if it is a html tag, add target attribute and re-convert to string
                if(this.isHtml(text)) {
                    var doc = new DOMParser().parseFromString(text, "text/xml");
                    var a = doc.getElementsByTagName('a')[0];
                    a.setAttribute('target', '_blank');
                    return a.outerHTML;
                }
            } catch (e) {
                // Error
            }
            

            return text
                .replace(urlRegexImage, function (url) {
                    return '<a target="_blank" href="' + url + '">' + url + '</a>';
                    //return url;
                    //downloadLinkCounterImage++;
                    //return '<button class="link image" data-href="' + url + '">download image ' + (++downloadLinkCounterImage) + '</button>'
                })
                .replace(urlRegexVideo, function (url) {
                    return '<a target="_blank" href="' + url + '">' + url + '</a>';
                    //downloadLinkCounterVideo++;
                    //return '<button class="link video" data-href="' + url + '">download video ' + (++downloadLinkCounterVideo) + '</button>'
                })
                .replace(urlRegexDoc, function (url) {
                    return '<a target="_blank" href="' + url + '">' + url + '</a>';
                    //downloadLinkCounterDoc++;
                    //return '<button class="link doc" data-href="' + url + '">download pdf ' + (++downloadLinkCounterDoc) + '</button>'
                });
        },

        /**
         * Check if text is a html string.
         * 
         * @param {string} text 
         */
        isHtml(text) {
            var a = document.createElement('div');
            a.innerHTML = text;

            for (var c = a.childNodes, i = c.length; i--; ) {
                if (c[i].nodeType == 1) return true; 
            }
        
            return false;
        },

        formatTimestamp(_timestamp) {
            var date = new Date(_timestamp);
            //var timestamp = date.getFullYear() + '/' + ('0' + (date.getMonth() + 1)).slice(-2) + '/' + ('0' + date.getDate()).slice(-2) + ' ' + date.getHours() + ':' + ('0' + date.getMinutes()).slice(-2);
            var timestamp = date.getHours() + ':' + ('0' + date.getMinutes()).slice(-2);
            return timestamp;
        },

        checkActive: function () {
            this.isActive = true;
            this.status = this.isActive ? 'Active' : 'Not Active';
        },
        toggleBadge: function () {
            this.badgeActive = true;
            this.badgeNumber = 1;
        },
        toggleMedia: function () {
            this.closeAll(4);
            if (!this.disableGallery) {
                this.bottomMedia = !this.bottomMedia;
            }
        },
        toggleChat: function () {
            this.hideChat = !this.hideChat;
        },
        hideChatCta: function () {
            this.hideChat = true;
        },
        toggleHoverIcon: function () {
            this.isHoveringIcon = !this.isHoveringIcon;
        },
        changeZoomLevel: function (el) {
            this.activeZoom = el;
            this.sendMessage('zoom ' + this.activeZoom);
        },
        changeActiveCtaSidebar: function (el) {
            if (this.activeCtaSidebar == el) {
                this.activeCtaSidebar = null;
            } else {
                this.activeCtaSidebar = el;
            }
        },
        increaseZoomLevel: function () {
            if (this.activeZoom == 5) {
                this.activeZoom = 5;
            } else {
                this.activeZoom++;
                this.sendMessage('zoom ' + this.activeZoom);
            }
        },
        decreaseZoomLevel: function () {
            if (this.activeZoom == 1) {
                this.activeZoom = 1;
            } else {
                this.activeZoom--;
                this.sendMessage('zoom ' + this.activeZoom);
            }
        },
        /**
         * Send message in chat
         *
         * @param {string} message
         */
        async sendMessage(message) {
            // TODO: improve message empty condition with regex pattern
            if (message !== '') {
                const sendMessageRequest = {
                    content: message,
                };

                let sendMessageOptions = {
                    senderDisplayName: this.$store.getters.getUserData.display_name,
                    type: 'text',
                };
                await this.chatThreadClient.sendMessage(sendMessageRequest, sendMessageOptions);
            }
        },
        validateMeetingParams(meeting_id, meeting_passcode) {
            return meeting_id !== undefined && meeting_passcode !== undefined;
        },
        /* join() {
      if (!this.validateMeetingParams(this.$route.params.meeting_id)) {
        this.textAlert = 'The meeting id may not be valid. Check the access data of the meeting';
        this.alertType = 'error';
        return;
      }

      window.expMain.joinMeeting(this.$route.params.meeting_id, this.$route.params.meeting_passcode, this.$store.getters.getUserData.display_name);
      this.localControls = true;
      //this.countParticipants = window.expMain.getCountParticipant();

      setTimeout(() => {
        this.disableGallery = false;
        (this.$store.getters.isLogged) ? this.loadGallery = true : this.loadGallery = false;
        //this.countParticipants = window.expMain.getCountParticipant();
        this.countParticipants = this.bjn.participantService.participants;

        if (this.$store.getters.isLogged)
          this.emitEventJoinMeetingRoom();
      }, 3000);
    }, */
        localControlsVideo() {
            this.muteVideo = window.expMain.toggleVideoMute();
        },
        localControlsAudio() {
            this.muteAudio = window.expMain.toggleAudioMute();
        },
        ControlsFlashlight() {
            this.statusLight = !this.statusLight;

            var status = this.statusLight ? 'on' : 'off';
            this.sendMessage('flashlight ' + status);
        },
        ControlsTakeFoto() {
            this.takePhoto = true;
            this.sendMessage('take photo');
          this.aloneMessage = 'The headset device is processing the command';
            setTimeout(() => {
            this.aloneMessage = '';
            }, 5000);
           /* setTimeout(() => this.textAlert = 'The HMT process command ',
            this.alertType = 'success', 5000) */
            setTimeout(() => (this.takePhoto = false), 250);
        },
        ControlsRecordVideo() {
            this.recordVideo = true;
            this.sendMessage('record video');
            this.aloneMessage = 'The headset device is processing the command';
            setTimeout(() => {
            this.aloneMessage = '';
            }, 5000);
            setTimeout(() => (this.recordVideo = false), 250);
        },
        ControlsAnnotations() {
            let remoteVideosGallery = document.getElementById('remoteVideosGallery');
            if(remoteVideosGallery.children.length > 1){
            if(this.remoteVideoPinnedID == null) {
                this.textAlert = 'Pin a remote video';
                return;
            }

            this.annotation = !this.annotation;

            if (!this.annotation) {
                this.$refs.annotationCanvas.clearCanvas();
                this.setAnnotationActiveTool('line');
            }
        }   else {
            this.annotation = !this.annotation;
            if (!this.annotation) {
            this.$refs.annotationCanvas.clearCanvas();
            this.setAnnotationActiveTool('line');
            }
        }
        },
        setAnnotationActiveTool(tool) {
            this.annotation_active_tool = tool;
            this.$refs.annotationCanvas.setActiveTool(tool);
        },
        clearTextareaMessage() {
            this.input_chat_message = '';
        },
        showContactList() {
            /* FIXME: la contactlist va in overflow sul 13" e non c'è scroll */
            if (this.$store.getters.isLogged) {
                this.hideContactList = !this.hideContactList;
            }
        },
        fileUploaded(file) {
            if (file) {
                this.alertType = 'success-2';
                this.textAlert = 'File: ' + file.name + ' loaded in gallery correctly!';
            } else {
                this.alertType = 'error';
                this.textAlert = 'Generic error';
            }
        },
        fileSent(url) {
            this.textAlert = 'File sent via chat';
            this.alertType = 'success';

            this.sendMessage(url);
        },
        showParticipantsList() {
            this.hideParticipantsList = !this.hideParticipantsList;
        },
        async closeMeetingRedirect() {
            if (this.inMeeting) {
                this.$store.commit('resetMeetingData');
                //this.$store.commit('resetUserMeetingData');
                await this.leaveMeeting();
            } 

            console.log('REDIRECT');
            window.location = `/start`;

            /*if(this.$store.getters.isLogged) {
        //this.$router.push('/meeting');
        window.location = '/meeting';
      } else {
        this.$router.push('/start');
      }*/
        },
        getStorageInfo() {
            this.valueStorageUsed = this.$store.getters.getUserStorage.usage;
            this.maxGb = this.$store.getters.getUserStorage.available;
            console.log(this.valueStorageUsed);
            console.log(this.maxGb);
        },
        closeAll: function (panel) {
            // TODO: da modificare in futuro. (Rendere tutto più dinamico e leggible)
            switch (panel) {
                case 1:
                    this.hideContactList = true;
                    this.hideParticipantsList = true;
                    this.bottomMedia = false;
                    break;
                case 2:
                    this.hideChat = true;
                    this.hideParticipantsList = true;
                    this.bottomMedia = false;
                    break;
                case 3:
                    this.hideChat = true;
                    this.hideContactList = true;
                    this.bottomMedia = false;
                    break;
                case 4:
                    this.hideChat = true;
                    this.hideContactList = true;
                    this.hideParticipantsList = true;
                    break;
            }
        },
        logout: function () {
            this.leaveMeeting();
            this.$store.commit('resetUserSessionData');
            this.$store.commit('resetMeetingData');
            window.location = `/start`;
        },
        emitEventRegister: function () {
            // FIXME: getUserData is undefined
            this.$socket.emit('register', {
                displayName: this.$store.getters.getUserData.display_name,
                email: this.$store.getters.getUserData.email,
            });
        },
        emitEventJoinMeetingRoom: function () {
            // Thread id in base64
            let threadId_base64 = btoa(this.$store.getters.getMeetingData.thread_id);
            this.$socket.emit('join room', 'meeting.teams.' + threadId_base64);
        },
        startMeeting: function (contacts) {
            this.$socket.emit('teams meet', contacts);
            this.textAlert = 'User: ' + contacts[0] + ' invited.';
            this.alertType = 'success';
        },
        inviteContacts: function (data) {
            console.log('Invite contacts');
            this.$socket.emit('teams meet invite', data.meeting, data.attendees);
            this.textAlert = 'Invite sent to ' + data.attendees.join(',');
            this.alertType = 'success';
        },
        showErrorEmailAlert: function (email) {
            // Empty spaces
            if (email.match(/^ *$/) !== null) {
                this.textAlert = 'Enter a valid email address';
            } else {
                this.textAlert = email + ' is not a valid email address';
            }

            this.alertType = 'error';
        },
        /**
         * End current call
         */
        async leaveMeeting() {
            console.log('LEAVE MEETING');
            
            if (this.inMeeting) {
                // end the current call
                await this.call.hangUp();
            }
            await this.unsubscribeEvents();
        },
        fullscreen: function () {
            if (!this.contentSharingIsFullscreen && this.remoteVideoPinnedID == null) {
                this.fullscreenVideo = !this.fullscreenVideo;

                if (this.annotation) {
                    setTimeout(() => {
                        this.$refs.annotationCanvas.resizeCanvas();
                    }, 2000);
                }
            }
        },
        clickIconChat: function () {
            this.changeActiveCtaSidebar(2);
            this.closeAll(1);
        },
        copy: function () {
            navigator.clipboard.writeText(window.location.href.replace('meeting', 'start'));
            this.alertType = 'success';
            this.textAlert = 'Meeting url copied';
        },
        onReceiveChatMessage: function (e) {
            if (this.checkMediaUrl(e.messageText)) {
                // put into the gallery
                this.$refs.galleryComponent.putMedia({ url: e.messageText });
            }
            // else if (this.checkZoomRemoteCommand(e.messageText)) {
            //   console.log('remote command received: zoom');
            //   this.onZoomRemoteCommandReceived(e.messageText);
            // } else if (this.checkFlashlightRemoteCommand(e.messageText)) {
            //   console.log('remote command received: flashlight');
            //   this.onFlashlightRemoteCommandReceived(e.messageText);
            // }
        },
        checkMediaUrl: function (url) {
            return this.checkImageURL(url) || this.checkVideoURL(url) || this.checkDocURL(url);
        },
        checkImageURL: function (url) {
            return url.toLowerCase().match(/\.(jpeg|jpg|gif|png)$/) != null;
        },
        checkVideoURL: function (url) {
            return url.toLowerCase().match(/\.(mp4|mov)$/) != null;
        },
        checkDocURL: function (url) {
            return url.toLowerCase().match(/\.(pdf)$/) != null;
            // },
            // checkRemoteCommand: function (text) {
            //   return text.toLowerCase().startsWith('zoom level')
            //       || text.toLowerCase().startsWith('flashlight')
            //       || text.toLowerCase().startsWith('take photo')
            //       || text.toLowerCase().startsWith('record photo');
            // },
            // checkZoomRemoteCommand: function(text) {
            //   return text.toLowerCase().startsWith('zoom');
            // },
            // onZoomRemoteCommandReceived: function (text) {
            //   if (this.checkZoomRemoteCommand(text)) {
            //     let parts = text.toLowerCase().split(' ');
            //     if (parts.length == 2 && !isNaN(parts[1])) {
            //       this.activeZoom = parts[1];
            //     }
            //   }
            // },
            // checkFlashlightRemoteCommand: function(text) {
            //   return text.toLowerCase().startsWith('flashlight');
            // },
            // onFlashlightRemoteCommandReceived: function (text) {
            //   if (this.checkFlashlightRemoteCommand(text)) {
            //     let parts = text.toLowerCase().split(' ');
            //     if (parts.length == 2) {
            //       this.statusLight = (parts[1] === 'on');
            //     }
            //   }
        },
        getAcsClientToken: async function () {
            let payloadToken = JSON.parse(localStorage.getItem('acs'));

            if (!payloadToken?.auth?.client?.communicationUserId) {
                // Get Azure Client Token
                let response = await this.$http.get(`${process.env.VUE_APP_TEAMS_BOT_SOCKET_URL}/acs/clients/token`, {
                    headers: { 'X-JUJO-Token': process.env.VUE_APP_TEAMS_BOT_API_TOKEN },
                });

                this.saveResponseToken('client', response.data);
                this.userId = response.data.communicationUserId;
                this.meetingLink = this.$route.query.link;

                return response.data.token;
                /* .then((response) => {
                        this.saveResponseToken(response.data);
                        this.userId = response.data.communicationUserId;
                        this.meetingLink = this.$route.query.link;

                        // Init ACS
                        this.initACS(response.data.token).then(() => {
                            this.meetingLink ? this.joinMeeting() : null;
                        });
                    })
                    .catch((err) => {
                        this.stateMessageText = 'Generic error';
                        console.log(err);
                    }); */
            } else {
                /* } else if (now.isAfter(payloadToken?.out.expiresOn)) { */
                // Refresh token
                this.userId = payloadToken.auth.communicationUserId;

                let response = await this.$http.post(
                    `${process.env.VUE_APP_TEAMS_BOT_SOCKET_URL}/acs/clients/token/refresh`,
                    { communicationUserId: payloadToken.auth.client.communicationUserId },
                    { headers: { 'X-JUJO-Token': process.env.VUE_APP_TEAMS_BOT_API_TOKEN } }
                );

                this.saveResponseToken('client', response.data);
                this.userId = response.data.communicationUserId;
                this.meetingLink = this.$route.query.link;

                return response.data.token;

                /* .then((response) => {
                        this.saveResponseToken(response.data);
                        this.userId = response.data.communicationUserId;
                        this.meetingLink = this.$route.query.link;

                        // Init ACS
                        this.initACS(response.data.token).then(() => {
                            this.meetingLink ? this.joinMeeting() : null;
                        });
                    })
                    .catch((err) => {
                        this.stateMessageText = 'ACS Client Token request error: ' + err;
                        console.log(err);
                    }); */
            }
        },
        getAcsUserToken: async function () {
            // Get Azure User Token
            let response = await this.$http.get(
                `${process.env.VUE_APP_TEAMS_BOT_SOCKET_URL}/acs/users/${
                    /*this.$store.getters.getUserData.email*/ 'test2@jujotech.io'
                }/auth?mode=silently`,
                {
                    headers: { 'X-JUJO-Token': process.env.VUE_APP_TEAMS_BOT_API_TOKEN },
                }
            );

            console.log('getAcsUserToken', response);

            if (response.status == 200) {
                this.saveResponseToken('user', response.data);
                this.userId = response.data.communicationUserId;
                this.meetingLink = this.$route.query.link;

                return response.data.token;
            } else {
                return this.getAcsClientToken();
            }

            /* .then((response) => {
                    this.saveResponseToken(response.data);
                    this.userId = response.data.communicationUserId;
                    this.meetingLink = this.$route.query.link;

                    // Init ACS
                    this.initACS(response.data.token).then(() => {
                        this.meetingLink ? this.joinMeeting() : null;
                    });
                })
                .catch((err) => {
                    //this.stateMessageText = 'ACS Client Token request error: ' + err;
                    console.log(err);

                    this.acsClientAuth();
                }); */
        },
    },
    computed: {
        /**
         * Show/hide the state message
         */
        showStateMessage() {
            return this.stateMessageText.length > 0;
        },
        /**
         * Show/hide the state message
         */
        showCoverPage() {
            return this.call?.state !== ('Connected' || 'InLobby');
        },
    },
    beforeMount() {
        this.checkActive();
        this.toggleBadge();
    },

    mounted() {
        //////
        //let payloadToken = JSON.parse(localStorage.getItem('acs'));

        // TODO: optimize it
        // Check if token exists and is not expired
        /* if (this.isLogged) {
            this.acsUserAuth();
        } else {
            this.acsClientAuth();
        } */

        this.initACS().then(() => {
            this.meetingLink ? this.joinMeeting() : null;
        });

        // Reset meeting data on exit page
        window.addEventListener('beforeunload', () => {
            this.$store.commit('resetMeetingData');
            //this.$store.commit('resetUserMeetingData');
            this.leaveMeeting();
        });

        ////////

        /* else {
      this.userId = payloadToken.auth.communicationUserId;

      // Init ACS
      this.initACS(payloadToken.auth.token).then(() => {
        this.meetingLink = this.$route.query.link;
        this.meetingLink ? this.joinMeeting() : null;
      });
    } */

        /* this.requestPermissions(() => {
      console.log('requestPermissions')
      this.availableCameras = this.bjn.videoDeviceService.availableCameras;
      this.attachVideoStreams();

      if (this.validateMeetingParams(this.$route.params.meeting_id, this.$route.params.meeting_passcode)) {
        this.joinMeeting();
      }

      this.startObservers();
    }); */

        /* ----- */

        /* this.$loadScript("/js/bluejeans/events/my.class.min.js");
    this.$loadScript("/js/bluejeans/external/require.js")
        .then(() => {
          this.$loadScript("/js/bluejeans/requirejs-config.js").then(() => {
            if (this.$route.params.meeting_id)
              setTimeout(() => {
                this.join() // FIXME: attenzione a questa chiamata ora che possiamo accedere a questa pagina anche senza meeting
              }, 3000);
          });
        });

    // check jquery - dummy code
    $("#null").text('jquery loaded'); */

        let userData = this.$store.getters.getUserData;

        if (!userData.email && !userData.password) {
            this.disableGallery = true;
        }
        this.getStorageInfo();

        // TODO: to improve
        if (this.inMeeting) {
            this.fusionRemoteMeetingUrl = window.location.href.replace('meeting', 'start');

            // Get link query string
            const params = new Proxy(new URLSearchParams(window.location.search), {
                get: (searchParams, prop) => searchParams.get(prop),
            });

            let meetingUrl = params.link;
            this.threadId = this.extractThreadIdFromMeetingLink(meetingUrl);
        }

        // chat message listener
        //window.addEventListener('chat_message_received', this.onReceiveChatMessage)
    },
    // FIXME: it is not working
    beforeDestroy() {
        this.$store.commit('resetMeetingData');
        this.leaveMeeting();
        //this.contentSharingInterval.clear();

        //this.$unloadScript("js/bluejeans/events/my.class.min.js");
        //this.$unloadScript("js/bluejeans/external/require.js");
        //this.$unloadScript("js/bluejeans/requirejs-config.js");
        //window.removeEventListener('chat_message_received', this.onReceiveChatMessage, false);
    },
    destroyed() {
        this.leaveMeeting();
        //this.$unloadScript("js/bluejeans/events/my.class.min.js");
        //this.$unloadScript("js/bluejeans/external/require.js");
        //this.$unloadScript("js/bluejeans/requirejs-config.js");

        //this.contentSharingInterval.clear();
        //window.removeEventListener('chat_message_received', this.onReceiveChatMessage, false);
    },
    watch: {
        textAlert: function (val) {
            if (val !== '') setTimeout(() => (this.textAlert = ''), 5000);
        },
        isReceivingContentShare: function (val) {
            if (val) {
                this.contentSharingVideoVisibility = '';
                return {
                    '"style"': this.contentSharingVideoVisibility,
                };
            } else {
                this.contentSharingVideoVisibility = 'none';
                this.fullscreenRemoteVideoButtonVisibility = 1;
                this.contentSharingIsFullscreen = false;

                return {
                    '"style"': [this.contentSharingVideoVisibility, this.fullscreenRemoteVideoButtonVisibility],
                };
            }
        },
        fullscreenVideo: function (val) {
            /* Visibility content share video when click fullscreen video remote */
            if (this.isReceivingContentShare) {
                if (val) {
                    this.contentSharingVideoVisibility = 'none';
                } else {
                    this.contentSharingVideoVisibility = '';
                }

                return {
                    '"style"': this.contentSharingVideoVisibility,
                };
            }

            return {'"style"': ''};
        },
        contentSharingIsFullscreen: function (val) {
            if (val) {
                this.fullscreenRemoteVideoButtonVisibility = 0;
            } else {
                this.fullscreenRemoteVideoButtonVisibility = 1;
            }

            return {
                '"style"': this.fullscreenRemoteVideoButtonVisibility,
            };
        },
    },
};
</script>

<style>
/* general classes */
.meeting {
    font-family: 'Lato', sans-serif;
}

.bold {
    font-weight: 600;
}

.small-txt {
    font-size: 14px;
}

.arrow-right {
    position: absolute;
    left: 0;
    width: 0;
    height: 0;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;

    border-left: 20px solid #b2b1ae;
}

.bottomMedia {
    /* bottom: 30px !important; */
}

.hide {
    display: none !important;
}

.bgGray {
    background: #808080 !important;
    transition: 0.1s;
}

.hoverIcon {
    /*background: red;*/
    fill: #ff6c11;
}

.activeCtaIcon {
    fill: black;
}

.bgTransparent {
    background: transparent;
}

/* top bar */

.top-bar {
    height: 80px;
    background: #e6e6e4;
}

.cover-page {
    position: absolute;
    top: 80px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background: black;
    opacity: 0.33;
    z-index: 100;
}

.company-name {
    font-weight: 600;
    font-size: 16px;
}

.company-name img {
    height: 30px;
    margin-left: 20px;
}

.storage-used {
    text-align: start;
    font-size: 12px !important;
}

.notification {
    position: relative;
}

.push-badge {
    position: absolute;
    bottom: 45%;
    left: 45%;
}

.profile-pic {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    margin-right: 10px;
}

.user {
    display: flex;
    align-items: center;
}

.meeting-url {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 75px;
}

.meeting-url p {
    margin-bottom: 0;
    font-weight: 600;
    font-size: 12px;
    padding-right: 5px;
}

.meeting-url span {
    font-size: 12px;
    margin-right: 10px;
}

/* remote video */

.remote-video-container {
    position: relative;
}

.loading-spinner {
    border: 12px solid #f3f3f3;
    border-radius: 50%;
    border-top: 12px solid #ca5010;
    width: 100px;
    height: 100px;
    -webkit-animation: spin 2s linear infinite;
    z-index: 2000;
    /* Safari */
    animation: spin 2s linear infinite;
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transform: translate(-50%, -50%);
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/* Safari */
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

.container-vid {
    /*height: calc(100vh - 75px);*/
    background-color: #232221;
    height: calc(100vh - 85px - 75px);
    padding: 0;
}

.localvid {
    position: absolute;
    z-index: 99;
}

.localvid > .localControls {
    left: 50%;
    bottom: 10%;
    margin: -64px 0 0 -64px;
    position: absolute;
    width: 128px;
    z-index: 1;
}

#localVideo {
    height: 125px;
    width: 200px;
    background-color: black;
}

/* Content sharing video */

.localvid-content > .content-sharing-fullscreen-toggle-button {
    top: 10%;
    left: 0px;
    margin-left: 26px;
    position: absolute;
    z-index: 50;
}

.localvid-content > .content-sharing-fullscreen-toggle-button-to-fullscreen {
    top: 5%;
    left: 0px;
    margin-left: 26px;
    position: absolute;
    z-index: 50;
}

.localvid-content {
    width: 400px;
    height: 250px;
    position: absolute;
    z-index: 99;
    bottom: 20px;
    right: 0%;
    background-color: #232221;
}

.content-sharing-fullscreen {
    height: 100% !important;
    width: 100% !important;
    bottom: 3%;
}

.content-video {
    height: 250px;
    background-color: black;
}

/*  Remote video */

.remotevid {
    /* height: calc(100vh - 75px - 80px); */
    position: absolute;
    top: 0px;
    bottom: 24px;
    left: 0px;
    right: 0px;
    width: auto;
    background: #232221;
}

/* Annotation tool */
.annotation-tool {
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right:0px;
}

/* chat */

.chat {
    position: absolute !important;
    right: 88px;
    height: calc(100% - 75px - 98px);
    padding: 30px 15px 50px 15px;
    background: #454545;
    transition: 0.1s background;
    top: 80px;
    bottom: 0;
    width: 30%;
    z-index: 100;
    border-left: 3px solid #e4e4e4;
}

.containerContactList,
.containerParticipantsList {
    position: absolute !important;
    right: 88px;
    height: calc(100% - 75px - 75px);
    transition: 0.1s background;
    z-index: 100;
    top: 80px;
    bottom: 0;
    width: 30%;
}

.message-container {
    /*margin-left: 15px;*/
    /*margin-right: 45px;*/
    padding: 10px;
    background: #ffffff;
    border-radius: 10px;
    margin-bottom: 30px;
    width: 100%;
}

.host-message {
    margin-left: 15px;
    margin-right: 45px;
}

.me-message {
    margin-left: 45px;
    margin-right: 15px;
}

.row-msg {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
}

.row-msg .profile {
    display: flex;
    align-items: center;
}

.chat-pic {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 15px;
    background-color: #ff6c11;
    color: white;
    margin-top: 2px;
}

.row-msg h4 {
    font-size: 20px;
    margin: 0;
}

.row-msg span {
    font-size: 20px;
}

.message {
    text-align: start;
}

.chat-input input {
    position: absolute;
    bottom: 50px;
    left: 0;
    border-radius: 6px;
    height: 35px;
    border: 1px solid lightgray;
    margin: 0 30px;
    width: 82%;
}

/* chat side bar */

.chat-sidebar {
    position: absolute !important;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
    top: 45px;
    height: calc(100vh - 75px - 98px);
    padding-top: 33px;
}

.chat-icon-container {
    display: flex;
    justify-content: center;
    align-items: center;

    top: -10px;
    height: 40px;
    margin-bottom: 24px;
}

.chat-profile-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1000;
}

.chat-sidebar-pic {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

/* media */

.media {
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    z-index: 101;
}

.gallery-content {
    width: 100%;
    display: flex;
    padding: 15px 45px;
    background: #dddddd;
    z-index: 101;
}

.media-content-container {
    display: flex;
    align-items: center;
    margin-right: 45px;
    height: 125px;
}

.plus-icon {
    width: 50px;
    height: 50px;
    color: #ffffff;
}

.toggle-gallery {
    width: 100%;
    text-align: start;
    background: #e4e4e4;
    color: #60605d;
    padding-left: 15px;
}

.toggle-gallery span {
    padding-left: 15px;
}

/* bottom bar */
.zoom-step {
    padding: 3px;
    background: #ffffff;
    border-radius: 50%;
    width: 30px;
    height: 30px;
}

.zoom-levels {
    display: flex;
    justify-content: space-between;
}

.zoom-level {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.zoom-level:not(:last-child) {
    margin-right: 15px;
}

.zoom-level p {
    margin-bottom: 0;
    color: #808080;
    font-size: 12px;
}

.circle-zoom {
    width: 17px;
    height: 17px;
    background: #808080;
    border-radius: 50%;
    border: 1px solid black;
    cursor: pointer;
}

.zoomActive {
    background: #9dd535;
    border: 1px solid #ffffff;
}

.zoom {
    width: 180px !important;
}

.bottom-bar {
    display: flex;
    justify-content: space-between;
    background: #454545;
    position: relative;
    z-index: 10;
}

.bottom-bar-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 80px;
}

.btn-container {
    padding: 16px 25px;
    display: flex;
    justify-content: center;
}

.bottom-btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
    margin-right: 50px;
    border-radius: 3px;
    background: #323030;
    color: #ffffff;
    transition: border 0.1s;
    height: 48px;
    width: 100px;
}

.bottom-btn.parent {
    margin-right: 10px;
}

.bottom-btn.child {
    width: 35px;
    margin-right: 5px;
    padding: 0 2px;
}

.bottom-btn:focus {
    outline: 0;
}

.record-video-btn {
    width: 110px !important;
}

.mb-bottom-btn {
    margin-bottom: -3px;
}

.orange-border {
    border: 1px solid #ff6c11;
}

.orange-border:hover {
    border: 1px solid white;
    transition: 0.1s;
}

.no-border-change {
    border: 1px solid #ff6c11 !important;
}

.blue-border {
    border: 1px solid #00a2d7;
}

.yellowBorder {
    border: 1px solid #f2e724 !important;
}

.bottom-btn span {
    font-size: 12px;
}

.zoom-btn {
    width: 25px;
    background: inherit;
    border: 0;
}

.inputMessage {
    border-radius: 15px !important;
}

.full-screen {
    background: inherit;
    border: 0;
}

input[type='textarea'].inputMessage::-webkit-input-placeholder {
    font-size: 1px;
}

.row_chat_input {
    bottom: 25px;
    width: 100%;
}

.truncate {
    max-width: 200px;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.display_none {
    display: none;
}

.close-call {
    position: absolute !important;
    right: 11%;
}

#remoteVideo {
    /*width: 111%;*/
    /*height: calc(100% - 0px);*/
    height: calc(100% - 30px);
    width: auto;
}

.grid-container {
    display: inline-grid;
    grid-auto-flow: column;
}

.grid-item {
    text-align: center;
}

.pdlr20 {
    padding-left: 20px !important;
    padding-right: 20px !important;
}

/*@media screen and (min-width: 1400px) {*/
/*  #remoteVideo {*/
/*    !*height: unset;*!*/
/*    !*width: 100%;*!*/
/*    height: 97%;*/
/*    width: 100%;*/
/*    !* top: 0; *!*/
/*    !* left: 0; *!*/
/*    !* right: 0; *!*/
/*    !* bottom: 0; *!*/
/*    z-index: -1;*/
/*  }*/

/*  .remoteVideoTablet {*/
/*    width: 100% !important;*/
/*    object-fit: fill !important;*/
/*    height: 118% !important;*/
/*  }*/
/*}*/

.remoteVideoTablet {
    height: 100vh !important;
}

@media screen and (max-width: 1300px) {
    #remoteVideo {
        /*height: calc(100% - 0px);*/
    }
}

#chat .ps {
    width: 100%;
    max-width: 1120px;
    height: 90%;
    overflow: auto;
    overflow-x: hidden;
}

.fullscreenWidth {
    height: 100vh;
}

.changeFillParticipantsList > .all,
.changeFillParticipantsList > .border {
    fill: #ff6c11 !important;
}

.changeFillContactList > .all {
    fill: #ff6c11 !important;
}

.changeFillChat > .b420800f-7479-4660-ab00-dc8003c84947 {
    fill: #ff6c11 !important;
}

.fillBlackContactList:hover > .all {
    fill: black;
}

.fillBlackParticipantsList:hover > .all {
    fill: black;
}

.fillBlackChat:hover > .b420800f-7479-4660-ab00-dc8003c84947 {
    fill: black;
}

.unselectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
}
</style>

<style>
.ps .ps__rail-x:hover,
.ps .ps__rail-y:hover,
.ps .ps__rail-x:focus,
.ps .ps__rail-y:focus,
.ps .ps__rail-x.ps--clicking,
.ps .ps__rail-y.ps--clicking {
    background-color: unset !important;
}

.ps__rail-y:hover > .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y,
.ps__rail-y.ps--clicking .ps__thumb-y {
    background-color: #e4e4e4 !important;
}

.state-message {
    max-width: 200px;
    box-shadow: 5px 5px 10px black;
    position: absolute;
    left: 18px;
    bottom: 36px;
    font-weight: bold;
    letter-spacing: 0.1rem;
    background-color: #ff6c11;
    text-align: center;
    padding: 8px 16px;
    border: solid 1px;
    border-color: #ff6c11;
    border-radius: 10px;
    opacity: 80%;
    z-index: 101;
}

.pin-video-button {
    position: absolute;
    left: 24px;
    top: 50%;
    transform: translateY(-50%);
    width: 25px;
    height: 25px;
}
.alone-message {
  color: white;      
  text-align: center; 
  font-size: 40px; 
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  background-color: transparent;
  z-index: 10;
  margin: 150px;
}

.hidden {
    display: none;
}
</style>
